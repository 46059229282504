import {getTimeslots} from '../requests/index.js'

export default function connect({on, emit, report, globals: {store}}) {
    const {dispatch, state} = store

    on('get-timeslots', async function (teamId) {
        const companyId = state.company.id

        dispatch('loading/add', 'get-timeslots')

        try {
            const result = await getTimeslots(companyId, teamId)
            const timeslots = result.data

            emit('get-timeslots/done', timeslots)
        } catch (error) {
            report(error)
        } finally {
            dispatch('loading/remove', 'get-timeslots')
        }
    })

    on('get-more-timeslots', async function ({teamId, currentTimeslots}) {
        const companyId = state.company.id

        dispatch('loading/add', 'get-more-timeslots')

        try {
            const result = await getTimeslots(companyId, teamId, currentTimeslots)
            const timeslots = result.data

            emit('get-more-timeslots/done', timeslots)
        } catch (error) {
            report(error)
        } finally {
            dispatch('loading/remove', 'get-more-timeslots')
        }
    })
}
