export default {
    namespaced: true,

    state: {
        id: undefined
    },

    mutations: {
        SET_ID(state, string) {
            state.id = string
        },

        CLEAR(state) {
            state.id = undefined
        }
    },

    actions: {
        setId({commit}, id) {
            commit('SET_ID', id)
        },

        clear({commit}) {
            commit('CLEAR')
        }
    }
}
