<template>
    <div>
        <atom-label text="Billing" />

        <molecule-list>
            <molecule-list-item text="Subscription Status" append="Active" />
            <molecule-list-item text="Signup date" :append="createdAt" />
        </molecule-list>

        <template v-if="currentBillingPeriod">
            <atom-label text="Current billing period" />

            <molecule-list>
                <molecule-list-item text="Start" :append="currentBillingPeriod.start" />
                <molecule-list-item text="End" :append="currentBillingPeriod.end" />
                <molecule-list-item text="Users" :append="currentBillingPeriod.users" />
            </molecule-list>
        </template>

        <template v-if="billingHistory.length">
            <atom-label text="Billing history" />

            <molecule-list>
                <molecule-list-item
                    v-for="(item, index) in billingHistory"
                    :key="index"
                    :text="item.date"
                    :append="'Users: ' + item.uniqueActiveUsers"
                />
            </molecule-list>
        </template>
    </div>
</template>

<script>
    import {fetchBillingHistory} from '../../requests/company.js'

    import {getDateFormat, getMonthName} from '../../utilities/date.js'

    function getShortDateFormat(date) {
        return getDateFormat(date, {weekday: undefined, month: 'short'})
    }

    export default {
        data() {
            return {
                billingHistory: []
            }
        },

        computed: {
            id() {
                return this.$route.params.id
            },

            company() {
                return this.$store.getters['companies/getCompany'](this.id)
            },

            createdAt() {
                const createdAt = this.company.createdAt

                return createdAt ? getShortDateFormat(createdAt.toDate()) : undefined
            },

            currentBillingPeriod() {
                const data = this.company.currentBillingPeriod

                return data === undefined
                    ? false
                    : {
                          start: getShortDateFormat(data.start.toDate()),
                          end: getShortDateFormat(data.end.toDate()),
                          users: data.uniqueActiveUsers
                      }
            }
        },

        async created() {
            const result = await fetchBillingHistory(this.id)

            this.billingHistory = result
                .sort(function (a, b) {
                    return a.start.toDate() > b.start.toDate()
                })
                .map(function (item) {
                    const date =
                        getShortDateFormat(item.start.toDate()) +
                        ' - ' +
                        getShortDateFormat(item.end.toDate())

                    return {
                        date,
                        ...item
                    }
                })
        }
    }
</script>
