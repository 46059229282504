import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/storage'

const config = {
    apiKey: 'AIzaSyDf7De-hEG9TgrB5kpyGLI1uWPj8q3e4Io',
    authDomain: 'lytte-32505.firebaseapp.com',
    databaseURL: 'https://lytte-32505.firebaseio.com',
    projectId: 'lytte-32505',
    storageBucket: 'lytte-32505.appspot.com',
    messagingSenderId: '447578232653',
    appId: '1:447578232653:web:381dbcb8c245124f484904',
    measurementId: 'G-RDE28PB7XT'
}

const staging_config = {
    apiKey: 'AIzaSyBMCYbjVh43ZMJFvzxGLz8laEy77UGh450',
    authDomain: 'lytte-staging.firebaseapp.com',
    projectId: 'lytte-staging',
    storageBucket: 'lytte-staging.appspot.com',
    messagingSenderId: '267847824461',
    appId: '1:267847824461:web:8dcaaced119f5cd4fcd804',
    measurementId: 'G-6CG1ZVZC36'
}

if (!firebase.apps.length) {
    firebase.initializeApp(process.env.PROD_ENV === 'live' ? config : staging_config)
}

const firestore = firebase.firestore()
let functions = firebase.app().functions('europe-west1')

if (process.env.NODE_ENV === 'development') {
    firebase.auth().settings.appVerificationDisabledForTesting = true
}

//Use default browser language for sms verificaiton
firebase.auth().useDeviceLanguage()

if (process.env.EMULATOR_MODE) {
    firebase.auth().useEmulator('http://localhost:9099/')
    firestore.useEmulator('localhost', 8081)
    functions.useEmulator('localhost', 5001)
}

// firestore.enablePersistence({synchronizeTabs: true})

window.firebase = firebase
window.functions = functions
