<template>
    <layout-main
        :width="computedWidth"
        background
        :header-title="companyName"
        header-align="left"
    >
        <atom-company-logo v-if="admin === false" slot="header-left" :url="companyLogo" />
        <!-- TODO: support passing a letter as "icon" -->

        <atom-button v-if="admin" slot="header-right" grey @click="logout" text="Log out" />

        <atom-button
            v-else
            slot="header-right"
            grey
            icon="settings"
            route="settings"
            aria-label="Settings"
        />

        <template v-if="admin">
            <atom-title slot="top" text="Customers" />
            <customers />
        </template>

        <template v-else-if="isNotLoading('fetch-company')">
            <atom-flex
                v-if="hasTeams && incompleteTeams === false"
                slot="top"
                rules="auto, auto"
                space-between
                style="margin: 16px 0"
            >
                <molecule-select
                    :items="[
                        {label: 'Sent', route: 'home'},
                        {label: 'Scheduled', route: 'scheduled'}
                    ]"
                />

                <atom-button
                    icon="plus"
                    route="upload"
                    text="New"
                    aria-label="Create new track"
                />
            </atom-flex>

            <atom-confetti
                v-if="
                    isNotLoading('fetch-tracks') && (hasTeams === false || hasTracks === false)
                "
                slot="pre-wrapper"
            />

            <template v-if="hasTeams === false">
                <atom-label text="Welcome to Lytte!" />
                <atom-title
                    size="large"
                    tag="h2"
                    text="Start by creating your team and add listeners"
                />

                <atom-text
                    text="Your listeners are the ones who will receive the tracks you create, upload, and publish."
                />

                <atom-button route="create-team" full-width text="Create team" />

                <atom-text
                    small
                    light
                    text="You may alway create new teams under settings in the up right corner."
                />
            </template>

            <template v-else-if="incompleteTeams">
                <atom-label text="You may soon publish your first track" />
                <atom-title size="large" tag="h2" text="Add listeners and set a schedule" />

                <atom-text
                    text="For the team to be complete you need to add listeners and set a schedule for tracks."
                />

                <atom-button route="settings" full-width text="Go to settings" />

                <atom-text
                    small
                    light
                    text="You may always add and remove listeners and change schedule in settings."
                />
            </template>

            <template v-else-if="isNotLoading('fetch-tracks') && hasTracks === false">
                <atom-flex rules="auto, auto" space-between align="top">
                    <atom-label text="You're ready to begin!" />

                    <svg
                        width="44"
                        height="68"
                        viewBox="0 0 44 68"
                        fill="#EB5B50"
                        xmlns="http://www.w3.org/2000/svg"
                        style="margin-right: 40px"
                    >
                        <path
                            d="M37.465.055a2 2 0 011.514
                                 1.651l4.609 7.986a2 2 0
                                 01-3.372 2.146l-.092-.146-2.437-4.217C30.201
                                 35.892 18.73 55.953 3.2 67.6a2 2 0
                                 11-2.4-3.2c13.237-9.928 23.493-26.54
                                 30.715-49.88l.505-1.659c.65-2.174 1.275-4.404
                                 1.875-6.692l-4.562 2.632a2 2 0 01-2.64-.586l-.092-.146a2
                                 2 0 01.586-2.64l.146-.092L35.62.551c.475-.45
                                 1.16-.66 1.844-.496z"
                        />
                    </svg>
                </atom-flex>

                <atom-title size="large" tag="h2" text="Create a track" />

                <atom-text
                    text="You have added your first listeners, what will be your first message to them?"
                />
            </template>

            <router-view v-else-if="isNotLoading('create-track')" />
        </template>
    </layout-main>
</template>

<script>
    import customers from './customers.vue'

    export default {
        inject: ['emit'],

        components: {
            customers
        },

        computed: {
            isNotLoading() {
                return this.$store.getters['loading/hasNot']
            },

            admin() {
                return this.$store.state.user.data.role === 'ADMIN'
            },

            hasTeams() {
                return this.$store.getters['teams/hasEntries']
            },

            incompleteTeams() {
                const teams = this.$store.getters['teams/asArray']

                return teams.some(function (team) {
                    return team.hasListeners === false || team.hasSchedule === false
                })
            },

            hasTracks() {
                return this.$store.getters['tracks/hasEntries']
            },

            computedWidth() {
                return this.admin
                    ? 'medium'
                    : this.hasTeams === false || this.incompleteTeams
                    ? 'small'
                    : 'large'
            },

            company() {
                return this.$store.state.company.data
            },

            billing() {
                return this.$store.state.company.billing
            },

            companyName() {
                return this.billing.companyName
            },

            companyLogo() {
                return this.company.logo ? this.company.logo.downloadUrl : undefined
            }
        },
        methods: {
            logout() {
                this.emit('logout')
            }
        }
    }
</script>
