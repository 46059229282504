import {filterUndefined} from '../utilities/object.js'

import {fetchCompany, setCompany, uploadCompanyLogo} from '../requests/index.js'

export default function connect({on, once, emit, report, globals: {store, router}}) {
    const {dispatch, state, getters} = store
    const isLoading = getters['loading/has']

    router.beforeEach(function (to, from, next) {
        const companyPages = ['profile', 'billing']
        const isCompanyPage = companyPages.includes(to.name)

        if (isCompanyPage && isLoading('fetch-company')) {
            once('fetch-company/done', next)
        } else {
            next()
        }
    })

    on('fetch-company', async function () {
        const id = state.company.id

        dispatch('loading/add', 'fetch-company')

        try {
            const result = await fetchCompany(id)
            const {billingDetails, ...rest} = result

            dispatch('company/setData', rest)
            dispatch('company/setBilling', billingDetails)

            emit('fetch-company/done', result)
        } catch (error) {
            report(error)
        } finally {
            dispatch('loading/remove', 'fetch-company')
        }
    })

    on('set-up-company', async function ({companyName, companyLogo}) {
        const id = state.company.id

        const billingDetails = {companyName}

        dispatch('loading/add', {key: 'set-up-company', blocking: true})

        try {
            if (companyLogo) {
                await setCompany(id, {
                    billingDetails,
                    logo: {
                        downloadUrl: await uploadCompanyLogo(id, companyLogo),
                        cdnPath: `companyLogos/${id}`
                    }
                })
            } else {
                await setCompany(id, {
                    billingDetails
                })
            }

            emit('fetch-company')
            await once('fetch-company/done')

            dispatch('loading/remove', 'set-up-company')
            router.replace({name: 'home'})
        } catch (error) {
            dispatch('loading/remove', 'set-up-company')
            report(error)
        }
    })

    on('save-profile', async function (submitted) {
        const id = state.company.id

        const {logo, companyName} = submitted

        const billingDetails = {
            ...state.company.billing,
            companyName
        }

        dispatch('loading/add', {key: 'save-profile', blocking: true})

        try {
            if (logo) {
                await setCompany(id, {
                    billingDetails,
                    logo: {
                        downloadUrl: await uploadCompanyLogo(id, logo),
                        cdnPath: `companyLogos/${id}`
                    }
                })
            } else if (logo === false) {
                await setCompany(id, {
                    billingDetails,
                    logo: false
                })
            } else {
                await setCompany(id, {
                    billingDetails
                })
            }

            emit('fetch-company')
            await once('fetch-company/done')

            dispatch('loading/remove', 'save-profile')
            router.replace({name: 'settings'})
        } catch (error) {
            dispatch('loading/remove', 'save-profile')
            report(error)
        }
    })

    on('update-customer', async function ({companyId, ...submitted}) {
        dispatch('loading/add', {key: 'update-customer', blocking: true})

        try {
            await setCompany(companyId, filterUndefined(submitted))

            dispatch('loading/remove', 'update-customer')
        } catch (error) {
            dispatch('loading/remove', 'update-customer')
            report(error)
        }
    })

    on('save-billing', async function (submitted) {
        const id = state.company.id

        dispatch('loading/add', {key: 'save-billing', blocking: true})

        try {
            await setCompany(id, {billingDetails: submitted})

            emit('fetch-company')
            await once('fetch-company/done')

            dispatch('loading/remove', 'save-billing')
            router.replace({name: 'settings'})
        } catch (error) {
            dispatch('loading/remove', 'save-billing')
            report(error)
        }
    })
}
