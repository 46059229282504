// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat

const locale = 'en-GB' // for correct month/day order

export function getDateFormat(date, options) {
    return new Intl.DateTimeFormat(locale, {
        year: 'numeric',
        month: 'long',
        weekday: 'long',
        day: 'numeric',
        ...options
    }).format(date)
}

export function getDateTimeFormat(date) {
    return new Intl.DateTimeFormat(locale, {
        year: 'numeric',
        month: 'short',
        weekday: 'long',
        day: 'numeric',

        hour: 'numeric',
        hour12: false,
        minute: 'numeric'
    }).format(date)
}

export function getMonthName(date) {
    return new Intl.DateTimeFormat(locale, {month: 'long'}).format(date)
}

export function getRelativeTimeFormat(fromDate, toDate) {
    const secondsBetween = Math.round(toDate.getTime() / 1000 - fromDate.getTime() / 1000)
    const minutesBetween = Math.round(secondsBetween / 60)
    const hoursBetween = Math.round(minutesBetween / 60)
    const daysBetween = Math.round(hoursBetween / 24)
    const monthsBetween = Math.floor(daysBetween / 30)

    // needed until Safari 13 is no more
    const supportsRelativeTimeFormat = 'RelativeTimeFormat' in Intl

    const formatter = supportsRelativeTimeFormat
        ? (amount, unit) => new Intl.RelativeTimeFormat(locale).format(amount, unit)
        : function (amount, unit) {
              return `in ${amount} ${unit}` + (amount === 1 ? '' : 's')
          }

    return monthsBetween > 0
        ? formatter(monthsBetween, 'month')
        : daysBetween > 0
        ? formatter(daysBetween, 'day')
        : hoursBetween > 0
        ? formatter(hoursBetween, 'hour')
        : formatter(minutesBetween, 'minute')
}
