<template>
    <layout-main background>
        <atom-button
            slot="header-left"
            grey
            icon="left-arrow"
            route="home"
            aria-label="Back"
        />
        <atom-button slot="header-right" grey @click="logout" text="Log out" />

        <atom-title slot="top" text="Settings" />

        <atom-label light small text="Account" />

        <molecule-list-item
            tag="div"
            :text="userData.firstname + ' ' + userData.lastname || 'placeholder'"
            :label="userData.email || 'placeholder@example.net'"
            route="account"
        >
            <atom-button
                slot="right"
                grey
                icon="small-right-arrow"
                color="light-grey"
                aria-hidden="true"
            />
        </molecule-list-item>

        <atom-label light small text="Company" />

        <molecule-list
            :items="[
                {route: 'profile', text: 'Company', append: companyName},
                {route: 'billing', text: 'Billing'}
            ]"
        />

        <atom-label light small text="Team" />

        <molecule-list>
            <molecule-list-item
                v-for="team in teams"
                :key="team.id"
                :route="{name: 'team', params: {teamId: team.id}}"
                :text="team.name"
                :append="team.listenersCount"
            >
                <atom-icon
                    slot="left"
                    v-if="team.hasListeners === false || team.hasSchedule === false"
                    icon="warning"
                    color="yellow"
                />
            </molecule-list-item>

            <molecule-list-item route="create-team" :right="false">
                <span style="margin: 0 auto">Create new team</span>
            </molecule-list-item>
        </molecule-list>
    </layout-main>
</template>

<script>
    export default {
        inject: ['emit'],

        computed: {
            userData() {
                return this.$store.state.user.data
            },
            companyName() {
                return this.$store.state.company.billing.companyName
            },
            teams() {
                return this.$store.getters['teams/asArray']
            }
        },

        methods: {
            logout() {
                this.emit('logout')
            }
        }
    }
</script>
