import moment from 'moment'

import {fetchTeam, setTeam, createTeam, deleteTeam} from '../requests/index.js'

function isTeamRoute(route) {
    return route.matched.some(function (route) {
        return route.name === 'team'
    })
}

function onTeamRoute(router, callback) {
    router.beforeEach(function (to, from, next) {
        isTeamRoute(to) ? callback(to, from, next) : next()
    })
}

function onLeaveTeamRoute(router, callback) {
    router.beforeEach(function (to, from, next) {
        isTeamRoute(to) === false && isTeamRoute(from) ? callback(to, from, next) : next()
    })
}

export default function connect({
    on,
    once,
    emit,
    report,
    globals: {store, router, addEventListener}
}) {
    const {dispatch, state, getters} = store
    const isLoading = getters['loading/has']
    const hasEntry = getters['teams/hasEntry']

    onTeamRoute(router, async function (to, from, next) {
        const previousId = state.team.id
        const id = to.params.teamId

        const isInitial = previousId === undefined

        const changedTeam = isInitial === false && previousId !== id

        if (isLoading('fetch-company')) {
            await once('fetch-company/done')
        }

        if (hasEntry(id)) {
            if (isInitial || changedTeam || from.name === 'settings') {
                if (isInitial || changedTeam) {
                    dispatch('team/setId', id)
                }

                emit('fetch-team', id)

                if (changedTeam) {
                    dispatch('listeners/clear')
                }

                emit('fetch-listeners')
            }

            next()
        } else {
            next({name: 'settings', replace: true})
        }
    })

    addEventListener('beforeunload', function (event) {
        const team = state.teams[state.team.id]

        const unfinished = team && (team.hasListeners === false || team.hasSchedule === false)

        if (isTeamRoute(router.currentRoute) && unfinished) {
            if (process.env.NODE_ENV !== 'production') {
                console.log('"beforeunload prevent" would be called in production')
                return
            }

            event.returnValue = '' // chrome requirement
            event.preventDefault()
        }
    })

    onLeaveTeamRoute(router, async function (to, from, next) {
        const team = state.teams[state.team.id]

        if (team && (team.hasListeners === false || team.hasSchedule === false)) {
            dispatch('dialog/open', 'confirm-unfinished-team')

            once('confirm-unfinished-team', function (stay) {
                stay ? next(false) : next()
            })
        } else {
            next()
        }
    })

    on('fetch-company/done', function (result) {
        const teams =
            result.teams === undefined
                ? []
                : result.teams.map(function (team) {
                      const {listenersCount = 0} = team

                      return {
                          ...team,
                          $isPartiallyLoaded: true,
                          listenersCount,
                          hasListeners: listenersCount !== 0
                      }
                  })

        if (getters['teams/hasEntries']) {
            dispatch('teams/clear')
        }

        dispatch('teams/setAll', teams)
    })

    on('fetch-team', async function (id) {
        const companyId = state.company.id

        dispatch('loading/add', 'fetch-team-' + id)

        try {
            const team = await fetchTeam(companyId, id)

            const {listenersCount = 0, schedule} = team

            dispatch('teams/set', {
                ...team,
                listenersCount,
                hasListeners: listenersCount !== 0,
                hasSchedule: schedule ? schedule.days.length !== 0 : false
            })
            emit(`fetch-team-${id}/done`)
        } catch (error) {
            report(error)
        } finally {
            dispatch('loading/remove', 'fetch-team-' + id)
        }
    })

    on('fetch-listeners/done', function () {
        const team = state.teams[state.team.id]

        const listenersCount = getters['listeners/count']

        dispatch('teams/set', {
            ...team,
            listenersCount,
            hasListeners: listenersCount !== 0
        })
    })

    on('add-listener/done', function () {
        const team = state.teams[state.team.id]

        const listenersCount = getters['listeners/count']

        dispatch('teams/set', {
            ...team,
            listenersCount,
            hasListeners: listenersCount !== 0
        })
    })

    on('delete-listener/done', function () {
        const team = state.teams[state.team.id]

        const listenersCount = getters['listeners/count']

        dispatch('teams/set', {
            ...team,
            listenersCount,
            hasListeners: listenersCount !== 0
        })
    })

    on('create-team', async function (teamName) {
        const companyId = state.company.id

        dispatch('loading/add', {key: 'create-team', blocking: true})

        try {
            const id = await createTeam(companyId, {name: teamName})

            emit('fetch-team', id)
            await once(`fetch-team-${id}/done`)

            dispatch('loading/remove', 'create-team')
            router.replace({name: 'team', params: {teamId: id}})

            dispatch('notice/success', 'Team created')
        } catch (error) {
            dispatch('loading/remove', 'create-team')
            report(error)
        }
    })

    on('update-team-name', async function (teamName) {
        const companyId = state.company.id
        const teamId = state.team.id

        dispatch('loading/add', {key: 'update-team-name', blocking: true})

        try {
            await setTeam(companyId, teamId, {name: teamName})

            emit('fetch-team', teamId)
            await once(`fetch-team-${teamId}/done`)

            dispatch('loading/remove', 'update-team-name')
            router.replace({name: 'team', params: {teamId}})
        } catch (error) {
            dispatch('loading/remove', 'update-team-name')
            report(error)
        }
    })

    on('save-schedule', async function (schedule) {
        const companyId = state.company.id
        const teamId = state.team.id

        dispatch('loading/add', {key: 'save-schedule', blocking: true})

        try {
            await setTeam(companyId, teamId, {
                schedule: {
                    ...schedule,
                    utcOffset: moment().utcOffset()
                }
            })

            emit('fetch-team', teamId)
            await once(`fetch-team-${teamId}/done`)

            dispatch('loading/remove', 'save-schedule')
            router.replace({name: 'team', params: {teamId}})
        } catch (error) {
            dispatch('loading/remove', 'save-schedule')
            report(error)
        }
    })

    on('delete-team', async function () {
        const companyId = state.company.id
        const teamId = state.team.id

        dispatch('loading/add', {key: 'delete-team', blocking: true})

        try {
            await deleteTeam(companyId, teamId)

            dispatch('loading/remove', 'delete-team')
            dispatch('teams/remove', teamId)
            router.replace({name: 'settings'})
        } catch (error) {
            dispatch('loading/remove', 'delete-team')
            report(error)
        }
    })
}
