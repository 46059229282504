export default {
    namespaced: true,

    state: {
        phone: undefined,
        countryCode: undefined
    },

    mutations: {
        SET_PHONE(state, string) {
            state.phone = string
        },

        SET_COUNTRY_CODE(state, string) {
            state.countryCode = string
        }
    },

    actions: {
        setPhone({commit}, phone) {
            commit('SET_PHONE', phone)
        },

        setCountryCode({commit}, countryCode) {
            commit('SET_COUNTRY_CODE', countryCode)
        }
    }
}
