import moment from 'moment'

import {
    fetchTrack,
    fetchTrackFeedback,
    createTrack,
    uploadTrackFile,
    getNewTrackId,
    fetchTracksForCompany,
    deleteTrack,
    duplicateTrack
} from '../requests/index.js'

function isTrackRoute(route) {
    const trackPages = ['sent-track', 'scheduled-track']

    return trackPages.includes(route.name)
}

function onTrackRoute(router, callback) {
    router.beforeEach(function (to, from, next) {
        isTrackRoute(to) ? callback(to, from, next) : next()
    })
}

export default function connect({on, emit, once, report, globals: {store, router}}) {
    const {dispatch, state, getters} = store

    const isLoading = getters['loading/has']
    const isNotLoading = getters['loading/hasNot']
    const hasTrack = getters['tracks/hasEntry']

    router.beforeEach(function (to, from, next) {
        const isTrackOverviewRoute = getters['user/isLeader']
            ? to.name === 'home' || to.name === 'scheduled'
            : false

        if (isTrackOverviewRoute || isTrackRoute(to)) {
            if (isLoading('fetch-tracks') === false) {
                emit('fetch-tracks')
            }
        }

        next()
    })

    onTrackRoute(router, async function (to, from, next) {
        const id = to.params.id

        const isInitial = from.name === null

        if (isInitial) {
            await once('fetch-tracks/done')

            if (hasTrack(id)) {
                emit('fetch-track-feedback', id)

                next()
            } else {
                next({name: 'home', replace: true})
            }
        } else {
            if (to.name === 'sent-track') {
                if (isNotLoading('fetch-tracks') && isNotLoading('fetch-track-' + id)) {
                    emit('fetch-track', id)
                    console.log('called?')
                }

                if (isNotLoading('fetch-track-feedback-' + id)) {
                    emit('fetch-track-feedback', id)
                }
            }

            next()
        }
    })

    on('fetch-track', async function (id) {
        dispatch('loading/add', 'fetch-track-' + id)

        try {
            const track = await fetchTrack(id)

            dispatch('tracks/set', track)

            emit(`fetch-track-${id}/done`)
        } catch (error) {
            report(error)
        } finally {
            dispatch('loading/remove', 'fetch-track-' + id)
        }
    })

    on('fetch-tracks', async function () {
        const id = state.company.id

        dispatch('loading/add', 'fetch-tracks')

        try {
            const tracks = await fetchTracksForCompany(id)

            if (getters['tracks/isEmpty'] === false) {
                dispatch('tracks/clear')
            }

            dispatch('tracks/setAll', tracks)

            emit('fetch-tracks/done')
        } catch (error) {
            report(error)
        } finally {
            dispatch('loading/remove', 'fetch-tracks')
        }
    })

    on('fetch-track-feedback', async function (trackId) {
        const companyId = state.company.id

        dispatch('loading/add', 'fetch-track-feedback-' + trackId)

        try {
            const result = await fetchTrackFeedback(trackId, companyId)

            dispatch('feedback/set', {
                key: trackId,
                entries: result
            })
        } catch (error) {
            report(error)
        } finally {
            dispatch('loading/remove', 'fetch-track-feedback-' + trackId)
        }
    })

    on('create-track', async function ({title, teamId, timeslot}) {
        const companyId = state.company.id
        const file = state.upload.file
        const teamName = state.teams[teamId].name
        const leaderEmail = state.user.data.email

        dispatch('loading/add', {key: 'create-track', blocking: true})

        try {
            const id = getNewTrackId()
            const url = await uploadTrackFile(id, file)

            await createTrack({
                id,
                title,
                performAt: firebase.firestore.Timestamp.fromDate(new Date(timeslot)),
                performAtTimestamp: moment(timeslot).unix(),
                status: 'scheduled',
                worker: 'sending',
                trackDownloadUrl: url,
                options: {
                    companyId,
                    teamId,
                    teamName,
                    leaderEmail
                }
            })

            emit('fetch-track', id)
            await once(`fetch-track-${id}/done`)

            dispatch('loading/remove', 'create-track')
            dispatch('upload/clear')

            router.replace({name: 'scheduled'})
        } catch (error) {
            dispatch('loading/remove', 'create-track')

            if (error.message === 'Upload timed out') {
                dispatch(
                    'notice/warning',
                    'The upload timed out - maybe due to bad connection. Try again!'
                )
            } else {
                report(error)
            }
        }
    })

    on('delete-track', async function ({id, track}) {
        const scheduled = track.status === 'scheduled'

        dispatch('loading/add', {key: 'delete-track', blocking: true})

        try {
            await deleteTrack(id)

            dispatch('loading/remove', 'delete-track')
            dispatch('tracks/remove', id)
            router.replace({name: scheduled ? 'scheduled' : 'home'})
        } catch (error) {
            dispatch('loading/remove', 'delete-track')
            report(error)
        }
    })

    on('dupe-track', async function ({title, teamId, timeslot, sourceTrack}) {
        const companyId = state.company.id
        const teamName = state.teams[teamId].name
        const leaderEmail = state.user.data.email

        dispatch('loading/add', {key: 'create-track', blocking: true})

        try {
            const id = getNewTrackId()

            await duplicateTrack({
                id,
                sourceTrack
            })

            let url = await firebase.storage().ref(`tracks/${id}`).getDownloadURL()

            await createTrack({
                id,
                title,
                performAt: firebase.firestore.Timestamp.fromDate(new Date(timeslot)),
                performAtTimestamp: moment(timeslot).unix(),
                status: 'scheduled',
                worker: 'sending',
                trackDownloadUrl: url,
                options: {
                    companyId,
                    teamId,
                    teamName,
                    leaderEmail
                }
            })

            emit('fetch-track', id)
            await once(`fetch-track-${id}/done`)

            dispatch('loading/remove', 'create-track')
            dispatch('upload/clear')

            router.replace({name: 'scheduled'})
        } catch (error) {
            dispatch('loading/remove', 'create-track')

            if (error.message === 'Upload timed out') {
                dispatch(
                    'notice/warning',
                    'The upload timed out - maybe due to bad connection. Try again!'
                )
            } else {
                report(error)
            }
        }
    })
}
