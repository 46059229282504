//FOR ADMIN
export default {
    namespaced: true,

    state: {
        data: []
    },

    mutations: {
        SET_DATA(state, array) {
            state.data = array
        },

        CLEAR(state) {
            state.data = []
        }
    },

    actions: {
        setData({commit}, data) {
            commit('SET_DATA', data)
        },

        clear({commit}) {
            commit('CLEAR')
        }
    },

    getters: {
        getCompany(state) {
            return function (id) {
                return state.data.find(function (company) {
                    return id === company.id
                })
            }
        }
    }
}
