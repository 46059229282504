<template>
    <molecule-modal-dialog v-on="$listeners" ref="dialog">
        <template slot="header-left">
            <atom-background color="green" opacity="0.12" large-corner-radius>
                <atom-icon icon="settings" color="green" />
            </atom-background>

            <atom-title size="small" text="Change phone number" />
        </template>

        <template v-slot="{cancel}">
            <atom-form @submit="submit" :disabled="unchanged || loading">
                <molecule-phone-input
                    grey
                    name="phone"
                    :value="{countryCode: value.countryCode, phone: value.phoneNumber}"
                    :disabled="loading"
                    @change="update"
                />

                <br />

                <div id="recaptcha-container"></div>

                <atom-flex rules="auto, auto">
                    <atom-button
                        id="submit-button"
                        type="submit"
                        :disabled="unchanged || loading"
                        text="Confirm"
                    />
                    <atom-button grey text="Cancel" @click="cancel" />
                </atom-flex>
            </atom-form>
        </template>
    </molecule-modal-dialog>
</template>

<script>
    export default {
        inject: ['emit', 'once'],

        props: {
            phoneNumber: {
                type: String,
                required: true
            },

            countryCode: {
                type: String,
                required: true
            }
        },

        computed: {
            unchanged() {
                return (
                    this.value.phoneNumber === this.phoneNumber &&
                    this.value.countryCode === this.countryCode
                )
            },

            loading() {
                return this.$store.state.loading.hasOwnProperty('change-phone-number')
            }
        },

        data() {
            return {
                value: {
                    phoneNumber: undefined,
                    countryCode: undefined
                }
            }
        },

        methods: {
            update({countryCode, phone: phoneNumber}) {
                this.value.countryCode = countryCode
                this.value.phoneNumber = phoneNumber
            },

            submit() {
                const countryCode = this.value.countryCode
                const phoneNumber = this.value.phoneNumber.replace(/\s/g, '')

                this.emit('change-phone-number', {
                    countryCode,
                    phoneNumber
                })
            }
        },

        created() {
            const off = this.once('change-phone-number/done', () => {
                this.$refs.dialog.confirm()
            })

            this.$once('hook:beforeDestroy', off)

            this.value.phoneNumber = this.phoneNumber
            this.value.countryCode = this.countryCode
        }
    }
</script>
