<template>
    <layout-main background>
        <atom-button
            slot="header-left"
            grey
            icon="left-arrow"
            route="settings"
            aria-label="Back"
        />
        <atom-button
            slot="header-right"
            type="submit"
            form="form"
            :disabled="loading"
            text="Save"
        />

        <atom-title slot="top" text="Billing" />

        <atom-form id="form" @submit="submit" :disabled="loading">
            <atom-label small text="Company" />

            <molecule-text-input
                name="company-name"
                v-model="companyName"
                :disabled="loading"
                required
                placeholder="Company name"
            />

            <molecule-text-input
                name="organization-number"
                v-model="orgNumber"
                :disabled="loading"
                required
                placeholder="Organisation number"
            />

            <atom-label small text="Billing details" />

            <molecule-text-input
                name="street-address"
                v-model="streetAddress"
                :disabled="loading"
                required
                placeholder="Street address"
            />

            <atom-flex rules="139px">
                <molecule-text-input
                    name="postal"
                    v-model="postal"
                    :disabled="loading"
                    required
                    placeholder="Postal"
                />

                <molecule-text-input
                    name="city-or-area"
                    v-model="cityOrArea"
                    :disabled="loading"
                    required
                    placeholder="City or area"
                />
            </atom-flex>

            <atom-label small text="Invoice email (Optional)">
                <molecule-text-input
                    name="invoice-email"
                    type="email"
                    v-model="invoiceEmail"
                    :disabled="loading"
                    placeholder="invoice@acme.org"
                />
            </atom-label>
        </atom-form>
    </layout-main>
</template>

<script>
    export default {
        inject: ['emit'],

        data() {
            return {
                companyName: undefined,
                orgNumber: undefined,
                streetAddress: undefined,
                postal: undefined,
                cityOrArea: undefined,
                invoiceEmail: undefined
            }
        },

        computed: {
            loading() {
                return this.$store.state.loading.hasOwnProperty('save-billing')
            }
        },

        created() {
            const billing = this.$store.state.company.billing

            this.companyName = billing.companyName
            this.orgNumber = billing.orgNumber
            this.streetAddress = billing.streetAddress
            this.postal = billing.postal
            this.cityOrArea = billing.cityOrArea
            this.invoiceEmail = billing.invoiceEmail
        },

        methods: {
            submit(event) {
                const invoiceEmail =
                    this.invoiceEmail && this.invoiceEmail.length
                        ? this.invoiceEmail
                        : undefined

                const submitted = {
                    companyName: this.companyName,
                    orgNumber: this.orgNumber,
                    streetAddress: this.streetAddress,
                    postal: this.postal,
                    cityOrArea: this.cityOrArea,
                    ...(invoiceEmail && {invoiceEmail})
                }

                this.emit('save-billing', submitted)
            }
        }
    }
</script>
