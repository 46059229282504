<template>
    <molecule-modal-dialog v-on="$listeners" ref="dialog" @confirm="submit" @cancel="submit">
        <template slot="header-left">
            <atom-background color="yellow" opacity="0.12" large-corner-radius>
                <atom-icon icon="warning" color="yellow" />
            </atom-background>
        </template>

        <template v-slot="{confirm}">
            <atom-title type="h2" size="medium" text="Wait!" />

            <atom-text
                indent
                light
                text="For the team to be complete you need to add listeners and set a schedule for tracks."
            />

            <atom-form method="dialog" @submit="confirm">
                <atom-flex rules="auto, auto">
                    <atom-button type="submit" text="Continue setup" />
                    <atom-button grey text="Finish later" @click="cancel" />
                </atom-flex>
            </atom-form>
        </template>
    </molecule-modal-dialog>
</template>

<script>
    export default {
        inject: ['emit'],

        methods: {
            submit() {
                this.emit('confirm-unfinished-team', true)
            },

            cancel() {
                this.emit('confirm-unfinished-team', false)
                this.$refs.dialog.close()
            }
        }
    }
</script>
