import {setCompany} from './company'
import {createTrack, deleteTrack} from './tracks'

export async function uploadCompanyLogo(companyId, file) {
    const storageRef = firebase.storage().ref()

    const uploadTask = await storageRef.child(`companyLogos/${companyId}`).put(file)

    return await uploadTask.ref.getDownloadURL()
}

export function uploadTrackFile(trackId, file) {
    const timeout = 30000
    const storageRef = firebase.storage().ref()

    if (file === undefined) {
        throw new Error('No file provided for upload')
    }

    return new Promise(function (resolve, reject) {
        const uploadTask = storageRef.child(`tracks/${trackId}`).put(file)

        const timer = setTimeout(function () {
            uploadTask.cancel()
            reject(new Error('Upload timed out'))
        }, timeout)

        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, {
            error: reject,
            complete() {
                clearTimeout(timer)

                uploadTask.snapshot.ref.getDownloadURL().then(resolve).catch(reject)
            }
        })
    })
}

export function getImageUrl(cdnPath, width) {
    const hostingUrl = process.env.HOSTING_URL
    const cdnSlug = 'cdn/image'

    return `${hostingUrl}/${cdnSlug}/width=${width}/${cdnPath}`
}
