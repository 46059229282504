<template>
    <layout-main background>
        <atom-button
            slot="header-left"
            grey
            icon="left-arrow"
            route="home"
            aria-label="Back"
        />

        <atom-button slot="header-right" grey icon="trash-can" text="Delete" @click="remove" />

        <template slot="top">
            <atom-title :text="billingDetails.companyName" />

            <molecule-select
                style="margin-bottom: 16px"
                :items="[
                    {label: 'About', route: 'customer'},
                    {label: 'Payment', route: 'payment'}
                ]"
            />
        </template>

        <router-view />
    </layout-main>
</template>

<script>
    export default {
        inject: ['emit'],

        computed: {
            id() {
                return this.$route.params.id
            },

            company() {
                return this.$store.getters['companies/getCompany'](this.id)
            },

            billingDetails() {
                return this.company.billingDetails || {}
            }
        },

        methods: {
            remove() {
                const confirmResult = window.confirm(
                    'Are you sure you want to delete this customer?'
                )
                if (confirmResult) {
                    const status = this.company.status === 'DELETED' ? 'DELETED' : 'ACTIVE'
                    this.emit('delete-customer', {companyId: this.id, status})
                }
            }
        }
    }
</script>
