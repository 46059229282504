<template>
    <layout-main>
        <atom-title text="Set up your company" />

        <form @submit.prevent="submit">
            <atom-label small text="Company name" />

            <molecule-text-input
                name="company-name"
                v-model="companyName"
                :disabled="loading"
                required
                grey
                placeholder="Acme"
            />

            <atom-label small text="Logo (Optional)" />

            <molecule-file-input
                name="company-logo"
                :disabled="loading"
                ref="file-input"
                accept="image/png, image/jpeg"
                grey
                label="Import photo"
                @select="verifyLogo"
            />

            <atom-text small light>
                Your company logo must be square and .png or jpg.
                <br />
                The file size must not exceed 128 KB.
            </atom-text>

            <atom-button type="submit" :disabled="loading" full-width text="Complete" />
        </form>
    </layout-main>
</template>

<script>
    function createDataURL(file) {
        const reader = new FileReader()

        return new Promise(function (resolve, reject) {
            reader.addEventListener('load', function (event) {
                resolve(event.target.result)
            })

            reader.addEventListener('error', function () {
                reject(new Error('Error when reading file'))
            })

            reader.readAsDataURL(file)
        })
    }

    function createImagePreview(dataURL) {
        let image = new Image()

        return new Promise(function (resolve, reject) {
            image.addEventListener('load', function (event) {
                resolve(image)
            })

            image.addEventListener('error', function () {
                reject(new Error('Error when creating preview of image'))
            })

            image.src = dataURL
        })
    }

    export default {
        inject: ['emit'],

        data() {
            return {
                companyName: undefined
            }
        },

        computed: {
            loading() {
                return this.$store.state.loading.hasOwnProperty('set-up-company')
            }
        },

        methods: {
            async verifyLogo() {
                const input = this.$refs['file-input']
                const file = input.selected

                const kilobyte = file.size / 1000

                const isBig = kilobyte > 128

                if (isBig) {
                    this.$store.dispatch('notice/info', 'The logo was over 128kB.')
                    input.reset()
                } else {
                    try {
                        const dataURL = await createDataURL(file)
                        const image = await createImagePreview(dataURL)

                        const {height, width} = image

                        const ratio = height / width

                        if (ratio !== 1) {
                            this.$store.dispatch('notice/info', 'The logo was not square.')
                            input.reset()
                        }
                    } catch (error) {
                        if (error.message === 'Error when reading file') {
                            this.$store.dispatch(
                                'notice/warning',
                                'Cannot read the file. Perhaps it is corrupt?'
                            )
                        } else if (error.message === 'Error when creating preview of image') {
                            this.$store.dispatch(
                                'notice/warning',
                                'Cannot create a preview of the image. Perhaps it is corrupt?'
                            )
                        } else {
                            this.$store.dispatch('notice/warning', error)
                        }
                    }
                }
            },

            submit() {
                const companyName = this.companyName
                const companyLogo = this.$refs['file-input'].selected

                this.emit('set-up-company', {companyName, companyLogo})
            }
        }
    }
</script>
