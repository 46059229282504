var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('layout-main',{attrs:{"background":""}},[_c('atom-button',{attrs:{"slot":"header-left","grey":"","icon":"left-arrow","route":"upload","aria-label":"Back"},slot:"header-left"}),_vm._v(" "),_c('atom-button',{attrs:{"slot":"header-right","type":"submit","form":"form","disabled":_vm.loading,"text":"Publish"},slot:"header-right"}),_vm._v(" "),_c('atom-title',{attrs:{"slot":"top","tag":"h2","text":"New track"},slot:"top"}),_vm._v(" "),_c('atom-form',{attrs:{"id":"form","disabled":_vm.loading},on:{"submit":_vm.submit}},[_c('atom-label',{attrs:{"text":"Title","small":""}},[_c('molecule-text-input',{attrs:{"name":"title","disabled":_vm.isPublishing,"required":""},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}})],1),_vm._v(" "),_c('atom-label',{attrs:{"tag":"span","text":"Team","small":""}}),_vm._v(" "),_c('molecule-list',{attrs:{"tag":"fieldset","aria-label":"Select the receiving team"}},_vm._l((_vm.teams),function(ref){
var id = ref.id;
var name = ref.name;
return _c('molecule-list-item',{key:id,attrs:{"tag":"label","text":name}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.teamId),expression:"teamId"}],attrs:{"slot":"right","disabled":_vm.loading,"required":"","name":"team","type":"radio"},domProps:{"value":id,"checked":_vm._q(_vm.teamId,id)},on:{"change":[function($event){_vm.teamId=id},_vm.getTimeslots]},slot:"right"})])}),1),_vm._v(" "),(_vm.computedTimeslots.length)?[_c('atom-label',{attrs:{"tag":"span","text":"Scheduling","small":""}}),_vm._v(" "),_c('molecule-list',{attrs:{"tag":"fieldset","appendable":"","aria-label":"Choose time slot"}},_vm._l((_vm.computedTimeslots),function(ref){
                    var value = ref.value;
                    var formatedDateTime = ref.formatedDateTime;
                    var formatedRelativeTime = ref.formatedRelativeTime;
return _c('molecule-list-item',{key:value,attrs:{"tag":"label","text":formatedDateTime,"label":formatedRelativeTime}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.timeslot),expression:"timeslot"}],attrs:{"slot":"right","disabled":_vm.loading,"required":"","name":"timeslot","type":"radio"},domProps:{"value":value,"checked":_vm._q(_vm.timeslot,value)},on:{"change":function($event){_vm.timeslot=value}},slot:"right"})])}),1),_vm._v(" "),_c('molecule-list-item',{attrs:{"tag":"button","type":"button","last":"","text":"Choose another"},on:{"click":_vm.getMoreTimeslots}})]:_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }