var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.teams.length)?[_c('atom-label',{attrs:{"text":"Teams"}}),_vm._v(" "),_c('molecule-list',_vm._l((_vm.teams),function(team){return _c('molecule-list-item',{key:team.id,attrs:{"text":team.name,"append":'Listeners: ' + team.listenersCount}})}),1)]:_vm._e(),_vm._v(" "),_c('atom-label',{attrs:{"text":"Company"}}),_vm._v(" "),_c('molecule-list-item',{attrs:{"tag":"div","text":"Name","append":_vm.billingDetails.companyName}}),_vm._v(" "),_c('molecule-list',{attrs:{"items":[
            {text: 'Name', append: _vm.billingDetails.streetAddress},
            {text: 'Postal code', append: _vm.billingDetails.postal},
            {text: 'City or area', append: _vm.billingDetails.cityOrArea},
            {
                text: 'Status',
                append: _vm.company.status === 'DELETED' ? 'Deleted by leader' : 'Active'
            }
        ]}}),_vm._v(" "),_c('molecule-list-item',{attrs:{"tag":"div","text":"Invoice email","append":_vm.billingDetails.invoiceEmail}}),_vm._v(" "),_c('atom-label',{attrs:{"text":"Account"}}),_vm._v(" "),_c('molecule-list-item',{attrs:{"tag":"div","text":"Name","append":_vm.company.leaderName}}),_vm._v(" "),_c('molecule-list',{attrs:{"items":[
            {text: 'Phone number', append: _vm.company.leaderPhoneNumber},
            {text: 'Email', append: _vm.company.leaderEmail}
        ]}}),_vm._v(" "),_c('atom-label',{attrs:{"text":"Extras"}}),_vm._v(" "),_c('atom-form',{attrs:{"disabled":_vm.loading},on:{"submit":_vm.submit}},[_c('atom-label',{staticClass:"small",attrs:{"text":"Twilio Message Service ID"}},[_c('molecule-text-input',{attrs:{"name":"message-service-id","disabled":_vm.loading},model:{value:(_vm.messageServiceId),callback:function ($$v) {_vm.messageServiceId=$$v},expression:"messageServiceId"}})],1),_vm._v(" "),_c('molecule-list-item',{attrs:{"tag":"label","text":"Extended track length limit"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.extendedTrackLengthLimit),expression:"extendedTrackLengthLimit"}],attrs:{"slot":"right","name":"extended-track-length-limit","type":"checkbox","disabled":_vm.loading},domProps:{"checked":Array.isArray(_vm.extendedTrackLengthLimit)?_vm._i(_vm.extendedTrackLengthLimit,null)>-1:(_vm.extendedTrackLengthLimit)},on:{"change":function($event){var $$a=_vm.extendedTrackLengthLimit,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.extendedTrackLengthLimit=$$a.concat([$$v]))}else{$$i>-1&&(_vm.extendedTrackLengthLimit=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.extendedTrackLengthLimit=$$c}}},slot:"right"})]),_vm._v(" "),_c('atom-button',{attrs:{"type":"submit","disabled":_vm.loading,"text":"Save"}})],1),_vm._v(" "),_c('br'),_c('br'),_c('br'),_c('br')],2)}
var staticRenderFns = []

export { render, staticRenderFns }