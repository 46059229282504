import {setUser} from './user'

let phoneSignInState = undefined
let userState = undefined

async function isRegistered(phone) {
    const querySnapshot = await firebase
        .firestore()
        .collection('users')
        .where('phoneNumber', '==', phone)
        .get()

    if (querySnapshot.empty) {
        return false
    }

    const foundListeners = []
    querySnapshot.forEach((doc) => foundListeners.push(doc.data()))

    const hasLeader = foundListeners.find((l) => l.role === 'LEADER')
    const hasListener = foundListeners.find((l) => l.role === 'LISTENER')
    const hasAdmin = foundListeners.find((l) => l.role === 'ADMIN')

    if (hasAdmin) {
        return true
    }
    if (hasListener && !hasLeader) {
        return false
    }
    if (!hasListener && !hasLeader) {
        throw new Error('User not found.')
    }
    if (hasListener && hasLeader) {
        return true
    }

    return true
}

export function onAuthChange(callback, initial = false) {
    let isFirst = true

    // firebase.auth().onAuthStateChanged fires automatically when called,
    // which is unexpected,
    // as a button don't automatically get clicked when you add an event listener to it.
    return firebase.auth().onAuthStateChanged(function (user) {
        if (initial || isFirst === false) {
            callback(user)
        }

        if (isFirst) {
            isFirst = false
        }
    })
}

export async function signUpWithPhoneNumber({phone, ...user}, container) {
    const registered = await isRegistered(phone)

    if (registered === false) {
        try {
            const appVerifier = new firebase.auth.RecaptchaVerifier(container, {
                size: 'invisible'
            })

            const result = await firebase.auth().signInWithPhoneNumber(phone, appVerifier)

            phoneSignInState = result
            userState = user
        } catch (error) {
            throw error
        }
    } else {
        throw new Error('Number already registered.')
    }
}

export async function signInWithPhoneNumber(phone, container) {
    const registered = await isRegistered(phone)

    if (registered) {
        try {
            const appVerifier = new firebase.auth.RecaptchaVerifier(container, {
                size: 'invisible'
            })

            const result = await firebase.auth().signInWithPhoneNumber(phone, appVerifier)

            phoneSignInState = result
        } catch (error) {
            throw error
        }
    } else {
        throw new Error('User not found.')
    }
}

export const changePhoneNumber = async (phoneNumber, appVerifier) => {
    const provider = new firebase.auth.PhoneAuthProvider()
    const verificationId = await provider.verifyPhoneNumber(phoneNumber, appVerifier)

    const verificationCode = window.prompt(
        'Please enter the verification ' + 'code that was sent to your mobile device.'
    )

    const phoneCredential = await firebase.auth.PhoneAuthProvider.credential(
        verificationId,
        verificationCode
    )

    return await firebase.auth().currentUser.updatePhoneNumber(phoneCredential)
}

export async function confirmPhoneNumber(code) {
    if (phoneSignInState === undefined) {
        throw new Error('No state to verify')
    }

    const {user} = await phoneSignInState.confirm(code)

    const registered = await isRegistered(user.phoneNumber)

    if (registered) {
        return user
    } else {
        await createUser(
            {uid: user.uid, phoneNumber: user.phoneNumber, ...userState},
            'leader'
        )

        return user
    }
}

export async function createUser(user, role) {
    const createUser = functions.httpsCallable('callableCreateUser')
    const result = await createUser({user, role})

    return result
}

export async function logoutUser() {
    return await firebase.auth().signOut()
}

export async function deleteAccount() {
    const user = firebase.auth().currentUser

    if (user) {
        const deleteAccount = functions.httpsCallable('callableDeleteLeaderAccount')
        const result = await deleteAccount(user.uid)

        return await user.delete()
    } else {
        throw new Error('No user found')
    }
}

export async function deleteCustomer(companyId, status) {
    const deleteCustomer = functions.httpsCallable('callableDeleteCustomer')
    const result = await deleteCustomer({companyId, status})

    return result
}
