<template>
    <layout-main background>
        <atom-button
            slot="header-left"
            grey
            icon="left-arrow"
            route="settings"
            aria-label="Back"
        />
        <atom-button
            slot="header-right"
            type="submit"
            form="form"
            :disabled="loading"
            text="Save"
        />

        <atom-title slot="top" text="Account" />

        <atom-form id="form" @submit="submit" :disabled="loading">
            <atom-label small text="Name" />

            <atom-flex>
                <molecule-text-input
                    name="firstname"
                    v-model="firstname"
                    :disabled="loading"
                    required
                    placeholder="First name"
                />

                <molecule-text-input
                    name="lastname"
                    v-model="lastname"
                    :disabled="loading"
                    required
                    placeholder="Last name"
                />
            </atom-flex>

            <atom-label small text="Email">
                <molecule-text-input
                    name="email"
                    type="email"
                    v-model="email"
                    :disabled="loading"
                    required
                />
            </atom-label>

            <atom-label light small text="Phone number" />

            <atom-flex rules="auto, auto" space-between>
                <molecule-phone-input disabled :value="{countryCode, phone: phoneNumber}" />
                <atom-button text="Change" large @click="change" />
            </atom-flex>

            <atom-label light small text="Legal" />

            <molecule-list-item
                first
                tag="div"
                route="https://www.privacypolicies.com/live/406f9498-4f80-4369-83ea-02256d22071d"
                external
                text="Privacy policy"
            />

            <molecule-list-item
                last
                tag="div"
                route="https://www.termsfeed.com/live/65ff91b2-eebf-4ae6-8506-010d82c0ec27"
                external
                text="Terms of service"
            />

            <br /><!-- TEMP: until molecule-list-item plays nice with non-grouped items -->
            <molecule-list-item tag="button" text="Delete account" @click="remove" />
        </atom-form>
    </layout-main>
</template>

<script>
    export default {
        inject: ['emit'],

        data() {
            return {
                firstname: undefined,
                lastname: undefined,
                email: undefined
            }
        },

        computed: {
            loading() {
                return this.$store.state.loading.hasOwnProperty('save-account')
            },

            phoneNumber() {
                return this.$store.state.user.data.phoneNumber
            },

            countryCode() {
                return this.$store.state.user.data.countryCode
            }
        },

        created() {
            const userData = this.$store.state.user.data

            this.firstname = userData.firstname
            this.lastname = userData.lastname
            this.email = userData.email
        },

        methods: {
            submit() {
                const submitted = {
                    firstname: this.firstname,
                    lastname: this.lastname,
                    email: this.email
                }

                this.emit('save-account', submitted)
            },

            change() {
                const {phoneNumber, countryCode} = this

                this.$store.dispatch('dialog/open', {
                    type: 'change-phone-number',
                    phoneNumber,
                    countryCode
                })
            },

            remove() {
                const confirmResult = window.confirm(
                    'Are you sure you want to delete your account?'
                )
                if (confirmResult) {
                    this.emit('delete-account')
                }
            }
        }
    }
</script>
