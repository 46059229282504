export function getCurrentUser() {
    return new Promise(function (resolve, reject) {
        // since there are no other way to get the current user,
        // firebase.auth().onAuthStateChanged is used,
        // since it fires automatically at once when subscribed
        const subscription = firebase.auth().onAuthStateChanged(function (user) {
            subscription() // unsubscribes when called

            user ? resolve(user) : reject(new Error('No current user. Likely not logged in.'))
        })
    })
}

/* @format
 * User mutation and queries
 * - setUser
 * - subscribeToUser
 */
export const setUser = async (uid, user) => {
    try {
        const userRef = firebase.firestore().collection('users').doc(uid)
        const res = await userRef.set(
            {
                ...user
            },
            {merge: true}
        )

        return res
    } catch (e) {
        return e
    }
}

/*
 * subscribeToUser
 * Subscribes to updates on a user based on userId
 * @params
 * userId - uid: The id of the user
 * onUpdate: callback containing the updated user document
 * onError: callback containg error message when the query fails
 */
export const subscribeToUser = (userId, onUpdate, onError) => {
    const userRef = firebase.firstore().collection('users').doc(userId)

    userRef.onSnapshot(
        (doc) => {
            onUpdate(doc.data())
        },
        (error) => {
            onError(error)
        }
    )
}

/*
 * fetchUser
 * fetches a user based on userId
 * @params
 * userId: String - Id of the user you want to fetch
 * @returns
 * Success object
 * @throws
 * ErrorObject
 */
export const fetchUser = async (userId) => {
    const userRef = firebase.firestore().collection('users').doc(userId)
    const userDoc = await userRef.get()

    return userDoc.data()
}

/*
 * deleteUser
 * Deletes the currently signed in user
 * @params
 * uid: UserId of the currently signed in user
 * @returns
 * Success object
 * @throws
 * Error object
 */
export const deleteUser = async (uid) => {
    await firebase.auth().delete()
}
