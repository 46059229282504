//@format
export const setTeam = async (companyId, teamId, teamDetails) => {
    const teamRef = firebase
        .firestore()
        .collection('companies')
        .doc(companyId)
        .collection('teams')
        .doc(teamId)
    return await teamRef.update({
        ...teamDetails
    })
}

export const createTeam = async (companyId, teamDetails) => {
    const teamsRef = firebase
        .firestore()
        .collection('companies')
        .doc(companyId)
        .collection('teams')

    const result = await teamsRef.add(teamDetails)

    return result.id
}

export const deleteTeam = async (companyId, teamId) => {
    const teamRef = firebase
        .firestore()
        .collection('companies')
        .doc(companyId)
        .collection('teams')
        .doc(teamId)

    return await teamRef.delete()
}

export const fetchTeam = async (companyId, teamId) => {
    const teamRef = firebase
        .firestore()
        .collection('companies')
        .doc(companyId)
        .collection('teams')
        .doc(teamId)

    const teamDoc = await teamRef.get()

    return {
        id: teamDoc.id,
        ...teamDoc.data()
    }
}

export const subscribeToTeam = (companyId, teamId, onSnapshot) => {
    const teamRef = firebase
        .firestore()
        .collection('companies')
        .doc(companyId)
        .collection('teams')
        .doc(teamId)

    teamRef.onSnapshot((doc) => {
        onSnapshot(doc.data())
    })
}

export const setSchedule = async (companyId, teamId, timeslots) => {
    const companyRef = firebase
        .firestore()
        .collection('companies')
        .doc(companyId)
        .collection('teams')
        .doc(teamId)

    const resp = await companyRef.update({
        scheduling: timeslots
    })

    return resp
}

export const getTimeslots = async (companyId, teamId, existingDates = []) => {
    const availableTimeslots = functions.httpsCallable('callableAvailableTimeslots')

    return await availableTimeslots({companyId, teamId, existingDates})
}

export const fetchTeams = async (companyId) => {
    const teamsRef = firebase
        .firestore()
        .collection('companies')
        .doc(companyId)
        .collection('teams')

    const teamsSnapshot = await teamsRef.get()
    let teams = []

    teamsSnapshot.forEach((doc) => teams.push({id: doc.id, ...doc.data()}))

    return teams
}
