<template>
    <layout-main background>
        <atom-button
            slot="header-left"
            grey
            icon="left-arrow"
            route="listeners"
            aria-label="Back"
        />
        <atom-button slot="header-right" type="submit" form="form" text="Save" />

        <atom-title slot="top" :text="'Listener ' + listener.firstName" />

        <atom-form id="form" @submit="submit" :disabled="loading">
            <atom-label small text="First name">
                <molecule-text-input
                    name="firstname"
                    v-model="firstname"
                    :disabled="loading"
                    required
                />
            </atom-label>

            <atom-label small text="Last name">
                <molecule-text-input
                    name="lastname"
                    v-model="lastname"
                    :disabled="loading"
                    required
                />
            </atom-label>

            <atom-label small text="Phone number">
                <molecule-phone-input
                    name="phone"
                    :value="{countryCode, phone}"
                    @change="updatePhone"
                    :disabled="loading"
                    required
                    placeholder="Phone number"
                />
            </atom-label>
        </atom-form>

        <atom-label light small text="Left the team?" />

        <atom-button
            icon="trash-can"
            text="Delete listener"
            :disabled="loading"
            @click="remove"
        />
    </layout-main>
</template>

<script>
    export default {
        inject: ['emit'],

        data() {
            return {
                firstname: undefined,
                lastname: undefined,
                countryCode: undefined,
                phone: undefined
            }
        },

        computed: {
            id() {
                return this.$route.params.listenerId
            },

            listener() {
                return this.$store.state.listeners[this.id]
            },

            loading() {
                return (
                    this.$store.state.loading.hasOwnProperty('save-listener') ||
                    this.$store.state.loading.hasOwnProperty('delete-listener')
                )
            }
        },

        created() {
            this.firstname = this.listener.firstName
            this.lastname = this.listener.lastName
            this.phone = this.listener.phoneNumber
            this.countryCode = this.listener.countryCode
        },

        methods: {
            updatePhone({countryCode, phone}) {
                this.countryCode = countryCode
                this.phone = phone
            },

            submit() {
                const id = this.id

                const submitted = {
                    firstName: this.firstname,
                    lastName: this.lastname,
                    phoneNumber: this.phone,
                    countryCode: this.countryCode
                }

                this.emit('save-listener', {
                    ...submitted,
                    id
                })
            },

            remove() {
                if (window.confirm('Are you sure you want to delete this listener?')) {
                    this.emit('delete-listener', this.id)
                }
            }
        }
    }
</script>
