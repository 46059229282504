<template>
    <layout-main width="small">
        <atom-button
            slot="header-right"
            grey
            icon="cross"
            external
            replace
            route="https://lytte.io/"
            aria-label="Lytte's homepage"
        />

        <atom-title text="Welcome back, sign in with your phone number" />

        <atom-form @submit="submit" :disabled="loading">
            <molecule-phone-input
                name="phone"
                :value="{countryCode, phone}"
                @change="update"
                :disabled="loading"
                required
                grey
                placeholder="Phone number"
            />

            <div id="recaptcha-container"></div>

            <atom-text
                tag="small"
                small
                light
                text="We'll text you a code to verify this is you."
            />

            <atom-button
                id="submit-button"
                type="submit"
                :disabled="loading"
                full-width
                text="Text me a code"
            />
        </atom-form>

        <atom-text center text="Don't have an account?">
            <atom-link to="sign-up" text="Get started" />
        </atom-text>
    </layout-main>
</template>

<script>
    export default {
        inject: ['emit'],

        computed: {
            loading() {
                return this.$store.state.loading.hasOwnProperty('sign-in')
            },

            countryCode: {
                get() {
                    return this.$store.state.login.countryCode
                },

                set(value) {
                    this.$store.dispatch('login/setCountryCode', value)
                }
            },

            phone: {
                get() {
                    return this.$store.state.login.phone
                },

                set(value) {
                    this.$store.dispatch('login/setPhone', value)
                }
            }
        },

        methods: {
            update({countryCode, phone}) {
                this.countryCode = countryCode
                this.phone = phone
            },

            submit() {
                const countryCode = this.countryCode
                const phone = this.phone.replace(/\s/g, '')

                this.emit('sign-in', {countryCode, phone})
            }
        }
    }
</script>
