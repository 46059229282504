//@format

export async function fetchListener(companyId, teamId, listenerId) {
    const reference = firebase
        .firestore()
        .collection('companies')
        .doc(companyId)
        .collection('teams')
        .doc(teamId)
        .collection('listeners')
        .doc(listenerId)

    const document = await reference.get()

    const data = document.data()

    return {
        id: listenerId,
        ...data
    }
}

export const fetchListeners = async (companyId, teamId) => {
    const listenersRef = firebase
        .firestore()
        .collection('companies')
        .doc(companyId)
        .collection('teams')
        .doc(teamId)
        .collection('listeners')

    const listenersSnapshot = await listenersRef.get()

    let listeners = []
    listenersSnapshot.forEach((doc) => {
        listeners.push({
            id: doc.id,
            ...doc.data()
        })
    })

    return listeners
}

const checkPhonenumberUnique = async (
    companyId,
    teamId,
    listener,
    listenerDocId = null,
    leaderPhoneNumber
) => {
    const listeners = firebase
        .firestore()
        .collection('companies')
        .doc(companyId)
        .collection('teams')
        .doc(teamId)
        .collection('listeners')

    const listenersSnapshot = await listeners.get()

    if (listenersSnapshot.size > 0) {
        const listeners = []
        listenersSnapshot.forEach((doc) => listeners.push({id: doc.id, ...doc.data()}))
        const isSelf = listeners.find((l) => l.id === listenerDocId)

        if (listeners.length === 1 && isSelf) {
            return true
        }

        const match = listeners.find((l) => l.phoneNumber === listener.phoneNumber)

        if (match) {
            throw new Error('Phone number already registered')
        }
    }

    return true
}

export const addListener = async (companyId, teamId, listener, leaderPhoneNumber) => {
    const isPhonenumberUnique = await checkPhonenumberUnique(
        companyId,
        teamId,
        listener,
        null,
        leaderPhoneNumber
    )

    const listenersRef = firebase
        .firestore()
        .collection('companies')
        .doc(companyId)
        .collection('teams')
        .doc(teamId)
        .collection('listeners')

    const companyLeadersRef = firebase
        .firestore()
        .collection('companies')
        .where('leaderPhoneNumber', '==', listener.phoneNumber)

    let isLeader = leaderPhoneNumber === listener.phoneNumber

    const snapshot = await companyLeadersRef.get()

    if (snapshot.size > 0) {
        isLeader = true
    }

    const result = await listenersRef.add({companyId, ...listener, isLeader})

    return result.id
}

export const deleteListener = async (companyId, teamId, listenerId) => {
    const listenerRef = firebase
        .firestore()
        .collection('companies')
        .doc(companyId)
        .collection('teams')
        .doc(teamId)
        .collection('listeners')
        .doc(listenerId)

    await listenerRef.delete()
}

export async function updateListener(
    companyId,
    teamId,
    listenerId,
    listenerDetails,
    leaderPhoneNumber
) {
    const isPhonenumberUnique = await checkPhonenumberUnique(
        companyId,
        teamId,
        listenerDetails,
        listenerId,
        leaderPhoneNumber
    )

    const listenerRef = firebase
        .firestore()
        .collection('companies')
        .doc(companyId)
        .collection('teams')
        .doc(teamId)
        .collection('listeners')
        .doc(listenerId)

    return await listenerRef.update({
        ...listenerDetails
    })
}

export const batchSetListeners = async (payload) => {
    const batchCreate = functions.httpsCallable('callableBatchCreateListeners')
    const result = await batchCreate(payload)

    return result
}

export const subscribeToListeners = (companyId, teamId, onUpdate) => {
    const listenersRef = firebase
        .firestore()
        .collection('companies')
        .doc(companyId)
        .collection('teams')
        .doc(teamId)
        .collection('listeners')

    listenersRef.onSnapshot(
        (snapshot) => {
            let listeners = []
            querySnapshot.forEach((doc) => {
                listeners.push(doc.data())
            })
            onUpdate(listeners)
        },
        (error) => {
            console.warn('Error subscribing to listeners: ', error)
        }
    )
}
