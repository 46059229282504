<template>
    <molecule-modal-dialog v-on="$listeners" @confirm="submit">
        <template slot="header-left">
            <atom-background color="red" opacity="0.12" large-corner-radius>
                <atom-icon icon="trash-can" color="red" />
            </atom-background>

            <atom-title size="small" text="Delete" />
        </template>

        <template v-slot="{confirm, cancel}">
            <atom-title type="h2" size="medium" text="You sure?" />

            <atom-text
                indent
                light
                text="This cannot be undone. When you delete, you lose all related data."
            />

            <atom-form method="dialog" @submit="confirm">
                <atom-flex rules="auto, auto">
                    <atom-button type="submit" text="Confirm delete" />
                    <atom-button grey text="Cancel" @click="cancel" />
                </atom-flex>
            </atom-form>
        </template>
    </molecule-modal-dialog>
</template>

<script>
    export default {
        inject: ['emit'],

        props: {
            id: {
                type: String,
                required: true
            },

            track: {
                type: Object,
                default: {}
            }
        },

        methods: {
            submit() {
                this.emit('delete-track', {
                    id: this.id,
                    track: this.track
                })
            }
        }
    }
</script>
