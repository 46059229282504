var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('body',[_c('router-view'),_vm._v(" "),_vm._l((_vm.modals),function(ref){
var id = ref.id;
var type = ref.type;
var data = ref.data;
return _c(type,_vm._b({key:id,tag:"component",on:{"close":function($event){return _vm.close(id)}}},'component',data,false))}),_vm._v(" "),_vm._l((_vm.notices),function(ref,index){
var key = ref.key;
var type = ref.type;
var text = ref.text;
return [_c('molecule-notice-dialog',{key:'notice-' + key,attrs:{"type":type,"text":text,"position":index + 1}})]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }