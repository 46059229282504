import home from './pages/home.vue'
import sent from './pages/sent.vue'
import scheduled from './pages/scheduled.vue'
import track from './pages/track.vue'
import scheduled_track from './pages/scheduled-track.vue'
import upload from './pages/upload.vue'
import duplicate from './pages/duplicate.vue'
import publish from './pages/publish.vue'

import customer from './pages/customer/index.vue'
import customer_about from './pages/customer/about.vue'
import customer_payment from './pages/customer/payment.vue'

import sign_in from './pages/sign-in.vue'
import sign_up from './pages/sign-up.vue'
import verify_phone_number from './pages/verify-phone-number.vue'
import set_up_company from './pages/set-up-company.vue'

import settings from './pages/settings/index.vue'
import account from './pages/settings/account.vue'
import profile from './pages/settings/profile.vue'
import billing from './pages/settings/billing.vue'
import create_team from './pages/settings/create-team.vue'

import team from './pages/settings/teams/index.vue'
import team_name from './pages/settings/teams/team-name.vue'
import schedule from './pages/settings/teams/schedule.vue'

import listeners from './pages/settings/teams/listeners/index.vue'
import listener from './pages/settings/teams/listeners/listener.vue'
import listener_import from './pages/settings/teams/listeners/listener-import.vue'

import not_found from './pages/not-found.vue'

export default [
    {
        path: '/',
        component: home,
        children: [
            {
                path: '',
                name: 'home',
                component: sent
            },
            {
                path: 'scheduled',
                name: 'scheduled',
                component: scheduled
            }
        ]
    },
    {
        path: '/sent/:id',
        name: 'sent-track',
        component: track
    },
    {
        path: '/scheduled/:id',
        name: 'scheduled-track',
        component: scheduled_track
    },

    {
        path: '/upload',
        name: 'upload',
        component: upload
    },

    {
        path: '/duplicate/:id',
        name: 'duplicate',
        component: duplicate
    },

    {
        path: '/publish',
        name: 'publish',
        component: publish
    },

    {
        path: '/customer/:id',
        component: customer,
        children: [
            {
                path: '',
                name: 'customer',
                component: customer_about
            },

            {
                path: 'payment',
                name: 'payment',
                component: customer_payment
            }
        ]
    },

    {
        path: '/sign-in',
        name: 'sign-in',
        component: sign_in,
        meta: {public: true, authenticated: false}
    },

    {
        path: '/sign-up',
        name: 'sign-up',
        component: sign_up,
        meta: {public: true, authenticated: false}
    },

    {
        path: '/verify-phone-number',
        name: 'verify-phone-number',
        component: verify_phone_number,
        meta: {authenticated: false}
    },

    {
        path: '/set-up-company',
        name: 'set-up-company',
        component: set_up_company,
        meta: {authenticated: false}
    },

    {
        path: '/settings',
        name: 'settings',
        component: settings
    },

    {
        path: '/settings/account',
        name: 'account',
        component: account
    },

    {
        path: '/settings/profile',
        name: 'profile',
        component: profile
    },

    {
        path: '/settings/billing',
        name: 'billing',
        component: billing
    },

    {
        path: '/settings/create-team',
        name: 'create-team',
        component: create_team
    },

    {
        path: '/settings/teams',
        redirect: {name: 'settings'}
    },

    {
        path: '/settings/teams/:teamId',
        name: 'team',
        component: team,

        children: [
            {
                path: 'team-name',
                name: 'team-name',
                component: team_name
            },

            {
                path: 'schedule',
                name: 'schedule',
                component: schedule
            },

            {
                path: 'listeners',
                name: 'listeners',
                component: listeners
            },

            {
                path: 'listeners/import',
                name: 'listener-import',
                component: listener_import
            },

            {
                path: 'listeners/:listenerId',
                name: 'listener',
                component: listener
            }
        ]
    },

    {
        path: '*',
        name: '404',
        component: not_found,
        meta: {public: true}
    }
]
