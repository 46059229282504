let count = 0

export default {
    namespaced: true,

    state: {
        items: []
    },

    mutations: {
        ADD(state, item) {
            state.items.push(item)
        },

        REMOVE(state, id) {
            state.items = state.items.filter((item) => item.id !== id)
        }
    },

    actions: {
        open({commit}, value) {
            const id = (count += 1)

            const isShorthand = typeof value === 'string'

            const {type, ...data} = isShorthand ? {type: value} : value
            commit('ADD', {
                id,
                type,
                data
            })
        },

        close({commit}, id) {
            commit('REMOVE', id)
        }
    }
}
