import {
    setUser,
    fetchUser,
    changePhoneNumber,
    deleteAccount,
    deleteCustomer,
    fetchAllCompanies
} from '../requests/index.js'

export default function connect({on, emit, report, globals: {store, router}}) {
    const {dispatch, state} = store

    async function storeUser(id) {
        const user = await fetchUser(id)

        if (user === undefined || !user) {
            emit('logout')
            return null
        }

        dispatch('user/setData', user)

        if (user.role === 'LEADER') {
            const companyId = user.leaderForCompanyId

            dispatch('company/setId', companyId)
        }

        return user.role
    }

    async function storeAllCompanies() {
        const result = await fetchAllCompanies()

        dispatch('companies/setData', result)
    }

    on('save-account', async function (submitted) {
        const uid = state.user.data.uid

        dispatch('loading/add', {key: 'save-account', blocking: true})

        try {
            await setUser(uid, submitted)
            const userData = await fetchUser(uid)

            dispatch('loading/remove', 'save-account')
            router.replace({name: 'settings'})

            dispatch('user/setData', userData)
        } catch (error) {
            dispatch('loading/remove', 'save-account')
            report(error)
        }
    })

    on('change-phone-number', async function ({phoneNumber, countryCode}) {
        const uid = state.user.data.uid

        dispatch('loading/add', {key: 'change-phone-number', blocking: true})

        try {
            const appVerifier = new firebase.auth.RecaptchaVerifier('submit-button', {
                size: 'invisible'
            })

            await changePhoneNumber(phoneNumber, appVerifier)
            await setUser(uid, {countryCode, phoneNumber})
            await storeUser(uid)

            dispatch('loading/remove', 'change-phone-number')
            emit('change-phone-number/done')
        } catch (error) {
            const isInvalid =
                error.code === 'auth/invalid-verification-code' ||
                error.code === 'auth/missing-verification-code'

            dispatch('loading/remove', 'change-phone-number')

            if (isInvalid) {
                dispatch('notice/warning', 'Wrong code. Try again.')
            } else if (error.code === 'auth/credential-already-in-use') {
                dispatch('notice/warning', 'Phone number already in use')
            } else if (error.code !== 'auth/argument-error') {
                report(error)
            }
        }
    })

    on('delete-account', async function () {
        router.replace({name: 'sign-in'})
        await deleteAccount()

        dispatch('user/clear')
        dispatch('company/clear')
        localStorage.clear()
    })

    on('delete-customer', async function ({companyId, status}) {
        dispatch('loading/remove', {key: 'delete-customer', blocking: true})

        try {
            await deleteCustomer(companyId, status)
            await storeAllCompanies()

            dispatch('loading/remove', 'delete-customer')
            router.replace({name: 'home'})
        } catch (error) {
            dispatch('loading/remove', 'delete-customer')
            report(error)
        }
    })
}
