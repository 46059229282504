<template>
    <div>
        <template v-if="teams.length">
            <atom-label text="Teams" />

            <molecule-list>
                <molecule-list-item
                    v-for="team in teams"
                    :key="team.id"
                    :text="team.name"
                    :append="'Listeners: ' + team.listenersCount"
                />
            </molecule-list>
        </template>

        <atom-label text="Company" />
        <molecule-list-item tag="div" text="Name" :append="billingDetails.companyName" />
        <molecule-list
            :items="[
                {text: 'Name', append: billingDetails.streetAddress},
                {text: 'Postal code', append: billingDetails.postal},
                {text: 'City or area', append: billingDetails.cityOrArea},
                {
                    text: 'Status',
                    append: company.status === 'DELETED' ? 'Deleted by leader' : 'Active'
                }
            ]"
        />
        <molecule-list-item
            tag="div"
            text="Invoice email"
            :append="billingDetails.invoiceEmail"
        />
        <atom-label text="Account" />
        <molecule-list-item tag="div" text="Name" :append="company.leaderName" />
        <molecule-list
            :items="[
                {text: 'Phone number', append: company.leaderPhoneNumber},
                {text: 'Email', append: company.leaderEmail}
            ]"
        />

        <atom-label text="Extras" />
        <atom-form @submit="submit" :disabled="loading">
            <atom-label class="small" text="Twilio Message Service ID">
                <molecule-text-input
                    name="message-service-id"
                    v-model="messageServiceId"
                    :disabled="loading"
                />
            </atom-label>

            <molecule-list-item tag="label" text="Extended track length limit">
                <input
                    slot="right"
                    name="extended-track-length-limit"
                    type="checkbox"
                    v-model="extendedTrackLengthLimit"
                    :disabled="loading"
                />
            </molecule-list-item>

            <atom-button type="submit" :disabled="loading" text="Save" />
        </atom-form>

        <br /><br /><br /><br />
    </div>
</template>

<script>
    export default {
        inject: ['emit'],

        data() {
            return {
                messageServiceId: undefined,
                extendedTrackLengthLimit: undefined
            }
        },

        computed: {
            id() {
                return this.$route.params.id
            },

            company() {
                return this.$store.getters['companies/getCompany'](this.id)
            },

            billingDetails() {
                return this.company.billingDetails || {}
            },

            teams() {
                return this.company.teams || []
            },

            loading() {
                return this.$store.state.loading.hasOwnProperty('update-customer')
            }
        },

        methods: {
            submit() {
                this.emit('update-customer', {
                    companyId: this.id,

                    messageServiceId: this.messageServiceId,
                    extendedTrackLengthLimit: this.extendedTrackLengthLimit
                })
            }
        },

        created() {
            if (this.company.messageServiceId) {
                this.messageServiceId = this.company.messageServiceId
            }

            if (this.company.extendedTrackLengthLimit) {
                this.extendedTrackLengthLimit = this.company.extendedTrackLengthLimit
            }
        }
    }
</script>
