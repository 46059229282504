<template>
    <layout-main header-title="Track" background>
        <atom-button
            slot="header-left"
            grey
            icon="left-arrow"
            route="home"
            aria-label="Back"
        />

        <atom-button
            v-if="preview"
            slot="header-right"
            type="submit"
            form="form"
            :disabled="file === undefined"
            text="Next"
        />

        <molecule-file-input
            v-else
            slot="header-right"
            name="track"
            required
            title="Select file"
            icon-color="black"
            form="form"
            accept=".mp3,.m4a"
            :label="false"
            grey
            @select="save"
        />

        <atom-title v-if="preview" slot="top" tag="h2" text="Verify" />

        <form slot="top" id="form" @submit.prevent="submit" style="margin-bottom: 16px">
            <molecule-record-input
                v-if="preview === undefined"
                :has-access="access"
                :maxlength="extendedTracks ? 600 : 120"
                @access="access = true"
                @recorded="save"
            />

            <template v-else>
                <atom-text light>
                    Listen to the track and make sure the sound quality is good before you
                    continue.
                </atom-text>

                <molecule-player :url="preview">
                    <atom-button icon="trash-can" grey text="Discard" @click="clear" />
                </molecule-player>
            </template>
        </form>

        <atom-title tag="h2" text="Script" />

        <atom-text-area placeholder="Type or paste in content..." :min-lines="5" />
    </layout-main>
</template>

<script>
    import {createPreview, getDuration} from '../utilities/file.js'

    export default {
        data() {
            return {
                access: undefined
            }
        },

        computed: {
            company() {
                return this.$store.state.company.data
            },

            extendedTracks() {
                return this.company.extendedTrackLengthLimit === true
            },

            file() {
                return this.$store.state.upload.file
            },

            preview() {
                return this.$store.state.upload.preview
            }
        },

        methods: {
            async save(blob) {
                const extended = this.extendedTracks

                const megabytes = blob.size / 1000000
                const minute = 60

                const limit = extended ? 50 : 150

                const isSmall = megabytes < limit

                const preview = isSmall ? await createPreview(blob) : undefined

                const duration = preview ? await getDuration(preview) : undefined

                const isShort =
                    duration === undefined
                        ? undefined
                        : duration <= minute * (extended ? 10 : 2)

                if (isSmall) {
                    if (isShort) {
                        this.$store.dispatch('upload/setFile', blob)
                        this.$store.dispatch('upload/setPreview', preview)
                    } else {
                        this.$store.dispatch(
                            'notice/warning',
                            `File is over ${extended ? 'ten' : 'two'} minutes.`
                        )
                    }
                } else {
                    this.$store.dispatch(
                        'notice/warning',
                        `Way too big file. Keep it under ${limit}MB.`
                    )
                }
            },

            clear() {
                this.$store.dispatch('upload/clear')
            },

            submit() {
                this.$router.push({name: 'publish'})
            }
        }
    }
</script>
