import loading from './loading.js'
import login from './login.js'
import user from './user.js'
import company from './company.js'
import track from './track.js'
import team from './team.js'
import listener from './listener.js'
import timeslot from './timeslot.js'

export default function connect({eventEmitter, errorReporter, globals}) {
    const connections = [loading, login, user, company, track, team, listener, timeslot]

    connections.forEach(function (connect) {
        connect({
            ...eventEmitter,
            report: errorReporter,
            globals
        })
    })
}
