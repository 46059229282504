import Vue from 'vue'

export default {
    namespaced: true,

    state: {},

    mutations: {
        ADD(state, {key, ...object}) {
            Vue.set(state, key, object)
        },

        CLEAR_HIDDEN(state, key) {
            Vue.delete(state[key], 'hidden')
        },

        REMOVE(state, key) {
            Vue.delete(state, key)
        }
    },

    getters: {
        has(state) {
            return function (key) {
                return state.hasOwnProperty(key)
            }
        },

        hasNot(state) {
            return function (key) {
                return state.hasOwnProperty(key) === false
            }
        },

        isLoading(state) {
            const keys = Object.keys(state)

            return keys.length !== 0
        },

        isBlocked(state) {
            return Object.entries(state).some(function ([key, value]) {
                return value.blocking === true
            })
        }
    },

    actions: {
        add({commit, state}, value) {
            const isShorthand = typeof value === 'string'

            const {key, blocking, message, delay = 500} = isShorthand ? {key: value} : value

            commit('ADD', {
                key,
                ...(blocking && {blocking}),
                ...(message && {message}),
                ...(delay && {hidden: true})
            })

            if (delay) {
                setTimeout(function () {
                    if (state[key]) {
                        commit('CLEAR_HIDDEN', key)
                    }
                }, delay)
            }
        },

        remove({commit}, key) {
            commit('REMOVE', key)
        }
    }
}
