<template>
    <layout-main width="large" background :header-title="date">
        <atom-button
            slot="header-left"
            grey
            icon="left-arrow"
            route="scheduled"
            aria-label="Back"
        />

        <atom-button
            slot="header-right"
            grey
            icon="trash-can"
            aria-label="Delete"
            @click="remove"
        />

        <template slot="top">
            <atom-title tag="h2" size="xl" :text="track.title" />

            <molecule-player :id="track.id" :url="track.trackDownloadUrl" :duplicate="true" />
        </template>
    </layout-main>
</template>

<script>
    import {getDateFormat} from '../utilities/date.js'

    export default {
        inject: ['emit'],

        computed: {
            id() {
                return this.$route.params.id
            },

            track() {
                return this.$store.state.tracks[this.id]
            },
            date() {
                return getDateFormat(new Date(this.track.performAt))
            }
        },
        methods: {
            remove() {
                this.$store.dispatch('dialog/open', {
                    type: 'delete-track',
                    id: this.id,
                    track: this.track
                })
            }
        }
    }
</script>
