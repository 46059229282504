export default {
    namespaced: true,

    state: {
        data: {}
    },

    getters: {
        isAdmin(state) {
            return state.data.role === 'ADMIN'
        },

        isLeader(state) {
            return state.data.role === 'LEADER'
        }
    },

    mutations: {
        SET_DATA(state, object) {
            state.data = object
        },

        CLEAR(state) {
            state.data = {}
        }
    },

    actions: {
        setData({commit}, data) {
            commit('SET_DATA', data)
        },

        clear({commit}) {
            commit('CLEAR')
        }
    }
}
