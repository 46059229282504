<template>
    <layout-main>
        <atom-title text="404 !!" />

        <atom-link :to="isLoggedIn ? 'home' : 'sign-in'" replace text="Home" />
    </layout-main>
</template>

<script>
    export default {
        computed: {
            isLoggedIn() {
                return this.$store.state.user.data.uid !== undefined
            }
        }
    }
</script>
