<template>
    <layout-main>
        <atom-button
            slot="header-left"
            grey
            icon="left-arrow"
            route="settings"
            aria-label="Back"
        />
        <atom-button
            slot="header-right"
            type="submit"
            form="form"
            :disabled="loading"
            text="Save"
        />

        <atom-title text="Team name" />

        <atom-form id="form" @submit="submit" :disabled="loading">
            <molecule-text-input
                name="team-name"
                v-model="teamName"
                :disabled="loading"
                required
                placeholder="Market, Service, Business.."
                grey
            />

            <atom-text small light text="The team name is visible for your listeners." />
        </atom-form>
    </layout-main>
</template>

<script>
    export default {
        inject: ['emit'],

        data() {
            return {
                teamName: undefined
            }
        },

        computed: {
            loading() {
                return this.$store.state.loading.hasOwnProperty('create-team')
            }
        },

        methods: {
            submit() {
                this.emit('create-team', this.teamName)
            }
        }
    }
</script>
