import {
    getCurrentUser,
    fetchUser,
    fetchAllCompanies,
    onAuthChange,
    signInWithPhoneNumber,
    signUpWithPhoneNumber,
    confirmPhoneNumber,
    logoutUser
} from '../requests/index.js'

// https://router.vuejs.org/guide/advanced/navigation-guards.html#global-before-guards
function addGlobalGuard(router) {
    return new Promise(function (resolve) {
        const subscription = router.beforeEach(function (to, from, next) {
            resolve({
                targetRoute: to,
                next(...args) {
                    subscription() // unsubscribes when called
                    next(...args)
                }
            })
        })
    })
}

export default async function connect({on, emit, report, globals: {store, router}}) {
    const {dispatch, state} = store
    const {targetRoute, next} = await addGlobalGuard(router)

    async function storeAllCompanies() {
        const result = await fetchAllCompanies()

        dispatch('companies/setData', result)
    }

    async function storePrimary(userId) {
        const user = await fetchUser(userId)

        if (user === undefined || !user) {
            throw new Error('No current user. Likely not logged in.')
        } else {
            dispatch('user/setData', user)

            if (user.role === 'LEADER') {
                dispatch('company/setId', user.leaderForCompanyId)

                emit('fetch-company')
            } else if (user.role === 'ADMIN') {
                await storeAllCompanies()
            }
        }
    }

    dispatch('loading/add', 'start')

    try {
        const user = await getCurrentUser()

        await storePrimary(user.uid)

        if (targetRoute.meta.authenticated === false) {
            next({name: 'home', replace: true})
        } else {
            next()
        }
    } catch (error) {
        if (error.message === 'No current user. Likely not logged in.') {
            emit('logout')

            if (targetRoute.meta.public) {
                next()
            } else {
                next({name: 'sign-in', replace: true})
            }
        } else {
            report(error)
        }
    } finally {
        dispatch('loading/remove', 'start')
    }

    onAuthChange(function (user) {
        // logged out in a different tab
        if (user === null && state.user.data.uid) {
            if (router.currentRoute.name !== 'sign-in') {
                router.replace({name: 'sign-in'})
            }
        }
    })

    on('sign-in', async function ({phone}) {
        dispatch('loading/add', {key: 'sign-in', blocking: true})

        try {
            await signInWithPhoneNumber(phone, 'submit-button')

            dispatch('loading/remove', 'sign-in')
            router.replace({name: 'verify-phone-number'})
        } catch (error) {
            dispatch('loading/remove', 'sign-in')

            if (error.message === 'User not found.') {
                dispatch('notice/warning', 'No user registered with ' + phone)
            } else if (error.code === 'auth/too-many-requests') {
                dispatch(
                    'notice/warning',
                    'Too many attempts on this device - try again later'
                )
            } else {
                report(error)
            }
        }
    })

    on('sign-up', async function (submitted) {
        const phone = submitted.phone

        dispatch('loading/add', {key: 'sign-up', blocking: true})

        try {
            await signUpWithPhoneNumber(submitted, 'submit-button')

            dispatch('loading/remove', 'sign-up')
            router.replace({
                name: 'verify-phone-number',
                query: {next: 'set-up-company'}
            })
        } catch (error) {
            dispatch('loading/remove', 'sign-up')

            if (error.message === 'Number already registered.') {
                dispatch('notice/warning', phone + ' is already registered')
            } else {
                report(error)
            }
        }
    })

    on('verify-auth-code', async function ({code}) {
        const next = router.currentRoute.query.next || 'home'

        dispatch('loading/add', {key: 'verify-auth-code', blocking: true})

        try {
            const user = await confirmPhoneNumber(code)
            await storePrimary(user.uid)

            dispatch('loading/remove', 'verify-auth-code')
            router.replace({name: next})
        } catch (error) {
            dispatch('loading/remove', 'verify-auth-code')

            // The SMS code has expired. Please re-send the verification code to try again.

            if (error.code === 'auth/invalid-verification-code') {
                dispatch('notice/warning', 'Wrong code. Double check it?')
            } else if (error.code === 'auth/code-expired') {
                dispatch('notice/warning', 'That verification code is expired.')
            } else {
                report(error)
            }
        }
    })

    on('logout', async function () {
        router.replace({name: 'sign-in'})

        dispatch('user/clear')
        dispatch('company/clear')

        await logoutUser()
        localStorage.clear()
    })
}
