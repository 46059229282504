import './firebase.js'

import Bugsnag from '@bugsnag/js'

import Vue from 'vue'
import VueRouter from 'vue-router'
import Vuex from 'vuex'

import createEventEmitter from './utilities/event.js'
import connect from './connections/index.js'

import index from './index.vue'
import routes from './routes.js'
import * as modules from './stores/index.js'

import {register, namespaced} from './components/index.js'

import {
    background,
    button,
    card,
    company_logo,
    confetti,
    dialog,
    flex,
    form,
    gauge,
    icon,
    input,
    label,
    link,
    logo,
    notice,
    text,
    text_area,
    title
} from './components/atoms/index.js'

import {
    file_input,
    header,
    list,
    list_item,
    modal_dialog,
    record_input,
    notice_dialog,
    phone_input,
    player,
    select,
    text_input,
    time_input
} from './components/molecules/index.js'

import {main} from './components/layouts/index.js'

if (process.env.NODE_ENV === 'production') {
    Bugsnag.start({apiKey: '667e9145f383fed8ee4c2b126da20ccc', logger: null})

    Vue.config.errorHandler = function (error) {
        Bugsnag.notify(error)
    }
}

Vue.config.productionTip = false

Vue.use(VueRouter)
Vue.use(Vuex)

register(Vue, {
    ...namespaced('atom', {
        background,
        button,
        card,
        company_logo,
        confetti,
        dialog,
        flex,
        form,
        gauge,
        icon,
        input,
        label,
        link,
        logo,
        notice,
        text,
        text_area,
        title
    }),
    ...namespaced('molecule', {
        file_input,
        header,
        list,
        list_item,
        modal_dialog,
        record_input,
        notice_dialog,
        phone_input,
        player,
        select,
        text_input,
        time_input
    }),
    ...namespaced('layout', {main})
})

const {body} = document
const {localStorage, addEventListener} = window

const router = new VueRouter({
    routes,
    mode: 'history',
    fallback: false,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return {x: 0, y: 0}
        }
    }
})

const store = new Vuex.Store({
    modules,
    strict: process.env.NODE_ENV !== 'production'
})

const eventEmitter = createEventEmitter()

connect({
    eventEmitter,
    errorReporter(error) {
        if (process.env.NODE_ENV === 'production') {
            Bugsnag.notify(error)
        } else {
            console.warn(error)
        }

        store.dispatch('notice/warning', "Unhandled error. We've been notified.")
    },
    globals: {
        localStorage,
        addEventListener,
        router,
        store
    }
})

new Vue({
    el: body,
    router,
    store,
    provide: eventEmitter,
    render(create) {
        return create(index)
    }
})

window.addEventListener('unhandledrejection', function (event) {
    console.log(event)
    console.log(event.promise)
    Bugsnag.notify(event.reason)
})
