export function createPreview(blob) {
    const reader = new FileReader()

    return new Promise(function (resolve) {
        reader.addEventListener(
            'load',
            function (event) {
                resolve(event.target.result)
            },
            {once: true}
        )

        reader.readAsDataURL(blob)
    })
}

export function getDuration(url) {
    let element = document.createElement('audio')

    return new Promise(function (resolve) {
        element.addEventListener(
            'durationchange',
            function () {
                resolve(element.duration)
            },
            {once: true}
        )

        element.src = url
    })
}
