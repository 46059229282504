export default {
    namespaced: true,

    state: {
        id: undefined,
        data: {},
        billing: {}
    },

    mutations: {
        SET_ID(state, string) {
            state.id = string
        },

        SET_DATA(state, object) {
            state.data = object
        },

        SET_BILLING(state, object) {
            state.billing = object
        },

        CLEAR(state) {
            state.id = undefined
            state.data = {}
            state.billing = {}
        }
    },

    actions: {
        setId({commit}, id) {
            commit('SET_ID', id)
        },

        setData({commit}, data) {
            commit('SET_DATA', data)
        },

        setBilling({commit}, data) {
            commit('SET_BILLING', data)
        },

        clear({commit}) {
            commit('CLEAR')
        }
    }
}
