<template>
    <section>
        <template v-for="(items, month) in tracks">
            <atom-label tag="h2" :text="month" />

            <molecule-list :items="items">
                <template v-slot:append="{item}">
                    <atom-text
                        v-if="item.playedPercentage !== undefined"
                        class="sent-played-statistic"
                        light
                        small
                        aria-hidden="true"
                        title="Percentage of listeners that have played the track"
                        :text="item.playedPercentage + '%'"
                        style="margin-top: 3px; margin-right: 7px"
                    />

                    <atom-icon v-if="item.rating === undefined" icon="star" color="grey" />

                    <atom-text v-else aria-hidden="true">
                        <atom-icon icon="star" :color="getColor(item.rating)" />
                        {{ item.rating.toFixed(1) }}
                    </atom-text>
                </template>
            </molecule-list>
        </template>
    </section>
</template>

<script>
    import {getMonthName, getDateFormat} from '../utilities/date.js'

    export default {
        inject: ['emit'],

        computed: {
            tracks() {
                const tracks = this.$store.getters['tracks/asArray']
                const teams = this.$store.state.teams

                return Array.from(tracks) // needed because the sort below mutates
                    .filter(function (track) {
                        return track.status === 'completed'
                    })
                    .map(function (track) {
                        const team =
                            track.options && teams[track.options.teamId]
                                ? teams[track.options.teamId].name
                                : undefined

                        const playedPercentage =
                            track?.statistics?.playedPercentage === undefined
                                ? undefined
                                : track.statistics.playedPercentage

                        const rating =
                            track?.statistics?.averageRating === undefined
                                ? undefined
                                : track.statistics.averageRating

                        return {
                            id: track.id,
                            title: track.title,
                            date: new Date(track.performAt),
                            team,
                            playedPercentage,
                            rating
                        }
                    })
                    .sort(function (a, b) {
                        return a.date < b.date
                    })
                    .map(function (track) {
                        return {
                            text: track.title,
                            label:
                                getDateFormat(track.date) +
                                (track.team ? ' - ' + track.team : ''),
                            month: getMonthName(track.date),
                            route: {
                                name: 'sent-track',
                                params: {
                                    id: track.id
                                }
                            },
                            playedPercentage: track.playedPercentage,
                            rating: track.rating
                        }
                    })
                    .reduce(function (accumulator, track) {
                        accumulator[track.month]
                            ? accumulator[track.month].push(track)
                            : (accumulator[track.month] = [track])

                        return accumulator
                    }, {})
            }
        },

        methods: {
            getColor(rating) {
                switch (true) {
                    case rating === 0:
                        return 'grey'
                    case rating < 2:
                        return 'red'
                    case rating >= 2 && rating < 3.5:
                        return 'yellow'
                    case true:
                        return 'green'
                }
            }
        }
    }
</script>

<style>
    @media (max-width: 460px) {
        .sent-played-statistic {
            display: none;
        }
    }
</style>
