<template>
    <layout-main>
        <atom-button
            slot="header-right"
            grey
            icon="cross"
            external
            replace
            route="https://lytte.io/"
            aria-label="Lytte's homepage"
        />

        <atom-title text="Get started" />

        <atom-form @submit="submit" :disabled="loading">
            <atom-label small text="Name" />

            <atom-flex>
                <molecule-text-input
                    name="firstname"
                    v-model="firstname"
                    :disabled="loading"
                    required
                    grey
                    placeholder="First name"
                />

                <molecule-text-input
                    name="lastname"
                    v-model="lastname"
                    :disabled="loading"
                    required
                    grey
                    placeholder="Last name"
                />
            </atom-flex>

            <atom-label small text="Email">
                <molecule-text-input
                    name="email"
                    type="email"
                    v-model="email"
                    :disabled="loading"
                    required
                    grey
                />
            </atom-label>

            <atom-label small text="Phone number">
                <molecule-phone-input
                    name="phone"
                    :value="{countryCode, phone}"
                    @change="update"
                    :disabled="loading"
                    required
                    grey
                    placeholder="Phone number"
                />
            </atom-label>

            <div id="recaptcha-container" />

            <atom-text tag="small" small light>
                Signing up for a Lytte account means you agree to our
                <atom-link
                    external
                    to="https://www.privacypolicies.com/live/406f9498-4f80-4369-83ea-02256d22071d"
                    text="Privacy Policy"
                />
                and
                <atom-link
                    external
                    to="https://www.termsfeed.com/live/65ff91b2-eebf-4ae6-8506-010d82c0ec27"
                    text="Terms of Service"
                />.
            </atom-text>

            <atom-button
                id="submit-button"
                type="submit"
                :disabled="loading"
                full-width
                text="Create account"
            />
        </atom-form>

        <atom-text center text="Have an account?">
            <atom-link to="sign-in" text="Sign in" />
        </atom-text>
    </layout-main>
</template>

<script>
    export default {
        inject: ['emit'],

        data() {
            return {
                firstname: undefined,
                lastname: undefined,
                email: undefined
            }
        },

        computed: {
            loading() {
                return this.$store.state.loading.hasOwnProperty('sign-up')
            },

            countryCode: {
                get() {
                    return this.$store.state.login.countryCode
                },

                set(value) {
                    this.$store.dispatch('login/setCountryCode', value)
                }
            },

            phone: {
                get() {
                    return this.$store.state.login.phone
                },

                set(value) {
                    this.$store.dispatch('login/setPhone', value)
                }
            }
        },

        methods: {
            update({countryCode, phone}) {
                this.countryCode = countryCode
                this.phone = phone
            },

            submit() {
                const submitted = {
                    firstname: this.firstname,
                    lastname: this.lastname,
                    email: this.email,
                    countryCode: this.countryCode,
                    phone: this.phone.replace(/\s/g, '')
                }

                this.emit('sign-up', submitted)
            }
        }
    }
</script>
