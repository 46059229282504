export async function fetchTrack(id) {
    const reference = firebase.firestore().collection('tracks').doc(id)

    const document = await reference.get()

    const {performAt, ...rest} = document.data()

    return {
        id,
        performAt: performAt.toDate().toJSON(),
        ...rest
    }
}

export async function fetchTrackFeedback(trackId, companyId) {
    const query = firebase
        .firestore()
        .collection('listenersTracks')
        .where('trackId', '==', trackId)
        .where('companyId', '==', companyId)
        .where('comment', '!=', null)

    const querySnapshot = await query.get()

    return querySnapshot.docs.map(function (document) {
        const {id, rating, comment} = document.data()

        return {id, rating, comment}
    })
}

export async function fetchTracksForCompany(companyId) {
    const query = firebase
        .firestore()
        .collection('tracks')
        .where('options.companyId', '==', companyId)

    const querySnapshot = await query.get()

    return querySnapshot.docs.map(function (document) {
        const {performAt, ...rest} = document.data()

        return {
            id: document.id,
            performAt: performAt.toDate().toJSON(),
            ...rest
        }
    })
}

export function getNewTrackId() {
    const trackRef = firebase.firestore().collection('tracks').doc()

    return trackRef.id
}

export async function createTrack({id, ...rest}) {
    const tracksRef = firebase.firestore().collection('tracks').doc(id)

    return await tracksRef.set(rest)
}

export async function deleteTrack(trackId) {
    const trackRef = firebase.firestore().collection('tracks').doc(trackId)

    return await trackRef.delete()
}

export async function duplicateTrack({id, sourceTrack}) {
    const duplicate = functions.httpsCallable('callableDuplicateTrack')

    return await duplicate({id, sourceTrack})
}
