export default {
    namespaced: true,

    state: {
        file: undefined,
        preview: undefined
    },

    mutations: {
        SET_FILE(state, object) {
            state.file = object
        },

        SET_PREVIEW(state, object) {
            state.preview = object
        },

        CLEAR(state) {
            state.file = undefined
            state.preview = undefined
        }
    },

    actions: {
        setFile({commit}, file) {
            commit('SET_FILE', file)
        },

        setPreview({commit}, preview) {
            commit('SET_PREVIEW', preview)
        },
        clear({commit}) {
            commit('CLEAR')
        }
    }
}
