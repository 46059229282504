// until Safari 13 is no more
import {EventTarget} from 'event-target-shim'

// inpired by https://medium.com/@zandaqo/eventtarget-the-future-of-javascript-event-systems-205ae32f5e6b
export default function createEventEmitter() {
    const instance = new EventTarget()

    // due to the way EventTarget works with CustomEvent,
    // the event is passed to the listeners, rather than the emitted value,
    // so a wrapper is needed to pass the emitted value at event.detail instead
    let references = new WeakMap()

    function addEventListener(event, callback, options) {
        const referenced = references.has(callback)

        const wrapped = referenced
            ? undefined
            : function (event) {
                  callback(event.detail.value)
              }

        if (referenced === false) {
            references.set(callback, wrapped)
            instance.addEventListener(event, wrapped, options)
        }
    }

    function removeEventListener(event, callback) {
        if (references.has(callback)) {
            instance.removeEventListener(event, references.get(callback))
            references.delete(callback)
        }
    }

    function dispatchEvent(event, value) {
        // needs to be wrapped in an object,
        // else undefined is converted to null if used directly as detail value
        const wrapped = {value}

        instance.dispatchEvent(new CustomEvent(event, {detail: wrapped}))
    }

    function on(event, callback) {
        addEventListener(event, callback)

        return function off() {
            removeEventListener(event, callback)
        }
    }

    function once(...args) {
        const [event, callback] = args

        if (args.length === 1) {
            return new Promise(function (resolve) {
                addEventListener(event, resolve, {once: true})
            })
        } else {
            addEventListener(event, callback, {once: true})

            return function off() {
                removeEventListener(event, callback)
            }
        }
    }

    return {
        on,
        once,
        off: removeEventListener,
        emit: dispatchEvent
    }
}
