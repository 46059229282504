<template>
    <section>
        <atom-label tag="h2" text="Scheduled" />

        <molecule-list :items="tracks">
            <atom-icon slot="left" icon="clock" color="red" />
        </molecule-list>
    </section>
</template>

<script>
    import {getDateFormat} from '../utilities/date.js'

    export default {
        inject: ['emit'],

        computed: {
            tracks() {
                const tracks = this.$store.getters['tracks/asArray']
                const teams = this.$store.state.teams

                return Array.from(tracks) // needed because the sort below mutates
                    .filter(function (track) {
                        return track.status === 'scheduled'
                    })
                    .map(function (track) {
                        const team =
                            track.options && teams[track.options.teamId]
                                ? teams[track.options.teamId].name
                                : undefined

                        return {
                            id: track.id,
                            title: track.title,
                            date: new Date(track.performAt),
                            team
                        }
                    })
                    .sort(function (a, b) {
                        return a.date > b.date
                    })
                    .map(function (track) {
                        return {
                            text: track.title,
                            label:
                                getDateFormat(track.date) +
                                (track.team ? ' - ' + track.team : ''),
                            route: {name: 'scheduled-track', params: {id: track.id}}
                        }
                    })
            }
        }
    }
</script>
