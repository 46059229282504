<template>
    <body>
        <router-view />

        <component
            v-for="{id, type, data} in modals"
            :key="id"
            :is="type"
            v-bind="data"
            @close="close(id)"
        />

        <template v-for="({key, type, text}, index) in notices">
            <molecule-notice-dialog
                :key="'notice-' + key"
                :type="type"
                :text="text"
                :position="index + 1"
            />
        </template>
    </body>
</template>

<script>
    import delete_track from './modals/delete-track.vue'
    import change_phone_number from './modals/change-phone-number.vue'
    import confirm_unfinished_team from './modals/confirm-unfinished-team.vue'

    export default {
        components: {
            'delete-track': delete_track,
            'change-phone-number': change_phone_number,
            'confirm-unfinished-team': confirm_unfinished_team
        },

        computed: {
            modals() {
                return this.$store.state.dialog.items
            },

            notices() {
                const loading = Object.entries(this.$store.state.loading)
                    .map(function ([key, value]) {
                        return {
                            key,
                            ...value,
                            type: 'loading',
                            text: value.message || 'Loading...'
                        }
                    })
                    .filter(function (item) {
                        return !item.hidden
                    })

                const notices = this.$store.state.notice.items.map(function (item) {
                    return {
                        key: item.id,
                        type: item.type,
                        text: item.message
                    }
                })

                return [...loading, ...notices]
            }
        },

        methods: {
            close(id) {
                this.$store.dispatch('dialog/close', id)
            }
        }
    }
</script>

<style>
    @import './components/globals.css';

    body {
        margin: 0;
    }

    .firebase-emulator-warning {
        display: none;
    }
</style>
