<template>
    <layout-main width="large" background :header-title="date">
        <atom-button
            slot="header-left"
            grey
            icon="left-arrow"
            route="home"
            aria-label="Back"
        />

        <atom-button
            slot="header-right"
            grey
            icon="trash-can"
            aria-label="Delete"
            @click="remove"
        />

        <template slot="top">
            <atom-title tag="h2" size="xl" :text="track.title" />

            <molecule-player :id="track.id" :url="track.trackDownloadUrl" :duplicate="true" />
        </template>

        <atom-label tag="h3" text="Statistics" />

        <!-- TODO: maybe convert into a molecule -->
        <atom-card tag="section" v-if="track.statistics">
            <atom-flex>
                <div>
                    <atom-title tag="span" size="large">
                        <atom-icon icon="play" color="grey" />
                        {{ statistics.playedPercentage }}%
                    </atom-title>

                    <atom-text small light>
                        {{ statistics.playedCount }} listeners so far
                    </atom-text>

                    <atom-gauge :filled="statistics.playedPercentage" />
                </div>

                <div style="margin-left: 38px">
                    <atom-title tag="span" size="large">
                        <atom-icon icon="star" :color="statistics.ratingColor" />
                        {{ statistics.rating.toFixed(1) }}
                    </atom-title>

                    <atom-text small light> {{ statistics.ratingCount }} responses </atom-text>

                    <atom-gauge
                        :filled="statistics.ratingPercentage"
                        :color="statistics.ratingColor"
                    />
                </div>
            </atom-flex>
        </atom-card>

        <atom-text tag="small" small light>
            <atom-icon icon="lock" color="grey" />
            All responses are anonymous
        </atom-text>

        <template v-if="feedback.length">
            <atom-label tag="h3" text="Comments" />

            <atom-card tag="article" v-for="{id, comment, rating} in feedback" :key="id">
                <atom-text :text="comment" style="white-space: pre-wrap" />

                <atom-text light grey> {{ rating }} stars </atom-text>
            </atom-card>
        </template>
    </layout-main>
</template>

<script>
    import {getDateFormat} from '../utilities/date.js'

    export default {
        inject: ['emit'],

        computed: {
            id() {
                return this.$route.params.id
            },

            track() {
                return this.$store.state.tracks[this.id]
            },

            date() {
                return getDateFormat(new Date(this.track.performAt))
            },

            statistics() {
                const statistics = this.track.statistics

                const {
                    playedPercentage = 0,
                    playedCount = 0,
                    averageRating,
                    ratingSum,
                    ratingCount = 0
                } = statistics

                const rating = averageRating === undefined ? 0 : averageRating

                const ratingPercentage =
                    ratingCount === 0 ? 0 : ((ratingSum / ratingCount) * 100) / 5

                // TODO: copied from sent page - refactor into a function
                //  - perhaps useful as a component utility
                const ratingColor =
                    rating === 0
                        ? 'grey'
                        : rating < 2
                        ? 'red'
                        : rating >= 2 && rating < 3.5
                        ? 'yellow'
                        : 'green'

                return {
                    playedCount,
                    playedPercentage,
                    rating,
                    ratingCount,
                    ratingColor,
                    ratingPercentage
                }
            },

            feedback() {
                const feedback = this.$store.state.feedback[this.id]

                return feedback === undefined ? [] : feedback.entries
            }
        },

        methods: {
            remove() {
                this.$store.dispatch('dialog/open', {
                    type: 'delete-track',
                    id: this.id,
                    track: this.track
                })
            }
        }
    }
</script>
