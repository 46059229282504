<template>
    <section>
        <atom-label light small text="All customers" />
        <molecule-list :items="customers" />
    </section>
</template>

<script>
    export default {
        computed: {
            customers() {
                return this.$store.state.companies.data.map(function (company) {
                    return {
                        route: {
                            name: 'customer',
                            params: {
                                id: company.id
                            }
                        },
                        text: company.billingDetails.companyName
                    }
                })
            }
        }
    }
</script>
