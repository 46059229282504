<template>
    <layout-main width="large" background header-title="Add listeners">
        <atom-button
            slot="header-left"
            grey
            :text="reportedChanges ? 'Cancel' : undefined"
            icon="left-arrow"
            route="listeners"
            aria-label="Back"
        />

        <atom-button
            v-if="reportedChanges"
            slot="header-right"
            :disabled="loading"
            aria-label="Approve the changes described"
            text="Save"
            @click="save"
        />

        <template slot="top" v-if="report === undefined">
            <atom-title size="medium" text="Bulk import" />

            <atom-text>
                <atom-link external to="/lytte-bulk-import-template.csv">
                    Download the template
                </atom-link>

                <br />

                After uploading your CSV file you get a report of changes to review before you
                approve.
            </atom-text>

            <atom-form @submit.prevent>
                <molecule-file-input
                    name="csv"
                    required
                    :disabled="loading"
                    ref="input"
                    accept=".csv"
                    grey
                    label="Select CSV file"
                    @select="generateReport"
                />
            </atom-form>
        </template>

        <template slot="top" v-else>
            <atom-title size="medium" text="Review updates" />
        </template>

        <template v-if="report">
            <template v-if="reportedChanges">
                <atom-label light small text="Overview" />

                <molecule-list>
                    <molecule-list-item v-if="report.new.length">
                        <atom-text text="New listeners" />

                        <atom-text slot="right">
                            {{ report.new.length }}
                            <atom-icon icon="check-filled" color="green" />
                        </atom-text>
                    </molecule-list-item>

                    <molecule-list-item v-if="report.removed.length">
                        <atom-text text="Removed listeners" />

                        <atom-text slot="right">
                            {{ report.removed.length }}
                            <atom-icon icon="cross-filled" color="red" />
                        </atom-text>
                    </molecule-list-item>

                    <molecule-list-item v-if="report.updated.length">
                        <atom-text text="Updated listeners" />

                        <atom-text slot="right">
                            {{ report.updated.length }}
                            <atom-icon icon="refresh" color="grey" />
                        </atom-text>
                    </molecule-list-item>
                </molecule-list>
            </template>

            <template v-else>
                <!-- TODO: -->
                &lt; text that describes that the import resulted in nothing to save &gt;
            </template>

            <template v-if="report.invalid.length">
                <atom-label light small text="Unprocessed numbers" />

                <molecule-list>
                    <molecule-list-item
                        v-for="{
                            countryCode,
                            phoneNumber,
                            firstName,
                            lastName
                        } in report.invalid"
                        :key="phoneNumber"
                        :text="`${countryCode} ${phoneNumber.replace(countryCode, '')}`"
                        :append="`${firstName} ${lastName}`"
                    />
                </molecule-list>

                <atom-text small light>
                    Please make sure the phone number is correct and try uploading the CSV-file
                    again.
                </atom-text>
            </template>
        </template>
    </layout-main>
</template>

<script>
    // TEMP: copied from "molecules/phone-input.vue", should be in its own place
    const countryCodes = [
        '+47',
        '+46',
        '+45',
        '+49',
        '+44',
        '+1',
        '+86',
        '+852',
        '+65',
        '+61',
        '+91',
        '+31'
    ]

    function getTextContent(file) {
        const reader = new FileReader()

        return new Promise(function (resolve, reject) {
            reader.addEventListener('load', function (event) {
                resolve(event.target.result)
            })

            reader.addEventListener('error', function () {
                reject(new Error('Error when reading file'))
            })

            reader.readAsText(file)
        })
    }

    function getListeners(csv, {countryCodes = []} = {}) {
        // https://developer.mozilla.org/en-US/docs/Glossary/CRLF
        const CRLF = /\r?\n/

        const [header, ...lines] = csv.split(CRLF).filter(function (line) {
            return line !== ''
        })

        const delimeter =
            header === 'Country Code,Phone Number,First Name,Last Name'
                ? ','
                : header === 'Country Code;Phone Number;First Name;Last Name'
                ? ';'
                : undefined

        if (delimeter === undefined) {
            throw new Error('Invalid headers on first row')
        }

        const listeners = lines.map(function (line, index) {
            const columns = line
                .replace(/"/g, '')
                .trim()
                .split(delimeter)
                .filter(function (column) {
                    return column !== ''
                })

            if (columns.length === 4) {
                return {
                    countryCode: columns[0].startsWith('+') ? columns[0] : '+' + columns[0],
                    phoneNumber: columns[1].replace(/\s/g, ''),
                    firstName: columns[2],
                    lastName: columns[3]
                }
            } else {
                throw new Error(
                    columns.length < 4
                        ? `Missing columns in row ${index + 2}`
                        : `Row ${index + 2} contains a delimeter; a comma or semi-colon`
                )
            }
        })

        listeners.forEach(function (listener, index) {
            if (countryCodes.includes(listener.countryCode) === false) {
                throw new Error(`Unsupported country code on row ${index + 2}`)
            }
        })

        listeners.forEach(function (listener, index) {
            listeners.forEach(function ($listener, $index) {
                const duplicate =
                    index !== $index &&
                    listener.countryCode === $listener.countryCode &&
                    listener.phoneNumber === $listener.phoneNumber

                if (duplicate) {
                    throw new Error(
                        'Duplicate country code and phone number on row ' +
                            `${index + 2} and ${$index + 2}`
                    )
                }
            })
        })

        return listeners.map(function ({countryCode, phoneNumber, firstName, lastName}) {
            return {
                countryCode: countryCode,
                phoneNumber: countryCode + phoneNumber,
                firstName: firstName,
                lastName: lastName
            }
        })
    }

    export default {
        inject: ['emit', 'on'],

        data() {
            return {
                imported: undefined,
                report: undefined
            }
        },

        computed: {
            loading() {
                return (
                    this.$store.state.loading.hasOwnProperty('generate-import-report') ||
                    this.$store.state.loading.hasOwnProperty('import-listeners')
                )
            },

            reportedChanges() {
                const report = this.report

                return report === undefined
                    ? undefined
                    : report.new.length || report.removed.length || report.updated.length
            }
        },

        methods: {
            async generateReport() {
                const file = this.$refs.input.selected

                const kb = file.size / 1000

                const isBig = kb > 30

                if (isBig) {
                    this.$store.dispatch(
                        'notice/info',
                        'The file is over 30kB - it might take a while to process'
                    )
                }

                try {
                    const csv = await getTextContent(file)
                    const listeners = getListeners(csv, {countryCodes})

                    this.imported = listeners
                    this.emit('generate-import-report', listeners)
                } catch (error) {
                    if (error.message === 'Error when reading file') {
                        this.$store.dispatch(
                            'notice/warning',
                            'Cannot read the file. Perhaps it is corrupt?'
                        )
                    } else {
                        this.$store.dispatch('notice/warning', error)
                    }
                }
            },

            save() {
                this.emit('import-listeners', this.imported)
            }
        },

        created() {
            const unsubscribe = this.on('generate-import-report-success', (report) => {
                this.report = report
            })

            this.$once('hook:beforeDestroy', unsubscribe)
        }
    }
</script>
