import Vue from 'vue'

const keyProperty = 'id'

export default {
    namespaced: true,

    state: {},

    getters: {
        isEmpty(state) {
            const keys = Object.keys(state)

            return keys.length === 0
        },

        hasEntries(state) {
            const keys = Object.keys(state)

            return keys.length !== 0
        },

        count(state) {
            const keys = Object.keys(state)

            return keys.length
        },

        asArray(state) {
            return Object.entries(state).map(function ([key, value]) {
                return {
                    [keyProperty]: key,
                    ...value
                }
            })
        }
    },

    mutations: {
        SET_ALL(state, object) {
            Object.entries(object).forEach(function ([key, value]) {
                Vue.set(state, key, value)
            })
        },

        SET(state, {key, ...object}) {
            Vue.set(state, key, object)
        },

        REMOVE(state, key) {
            Vue.delete(state, key)
        },

        CLEAR(state) {
            Object.keys(state).forEach(function (key) {
                Vue.delete(state, key)
            })
        }
    },

    actions: {
        setAll({commit}, items) {
            const entries = items.reduce(function (accumulator, item) {
                accumulator[item[keyProperty]] = item

                return accumulator
            }, {})

            commit('SET_ALL', entries)
        },

        set({commit}, item) {
            const entry = {
                key: item[keyProperty],
                ...item
            }

            commit('SET', entry)
        },

        remove({commit}, key) {
            commit('REMOVE', key)
        },

        clear({commit}) {
            commit('CLEAR')
        }
    }
}
