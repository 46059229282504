import {
    fetchListener,
    addListener,
    fetchListeners,
    updateListener,
    deleteListener,
    batchSetListeners
} from '../requests/index.js'

export default function connect({on, once, emit, report, globals: {store, router}}) {
    const {dispatch, state, getters} = store
    const isLoading = getters['loading/has']

    router.beforeEach(async function (to, from, next) {
        if (to.name === 'listeners' && from.name === 'team') {
            emit('fetch-listeners')
        }

        if (to.name === 'listener' && isLoading('fetch-listeners')) {
            await once('fetch-listeners/done')
        }

        next()
    })

    on('fetch-listener', async function (id) {
        const companyId = state.company.id
        const teamId = state.team.id

        dispatch('loading/add', 'fetch-listener')

        try {
            const listener = await fetchListener(companyId, teamId, id)

            dispatch('listeners/set', listener)
            emit('fetch-listener/done')
        } catch (error) {
            report(error)
        } finally {
            dispatch('loading/remove', 'fetch-listener')
        }
    })

    on('fetch-listeners', async function () {
        const companyId = state.company.id
        const teamId = state.team.id

        dispatch('loading/add', 'fetch-listeners')

        try {
            const result = await fetchListeners(companyId, teamId)

            if (getters['listeners/hasEntries']) {
                dispatch('listeners/clear')
            }

            dispatch('listeners/setAll', result)
            emit('fetch-listeners/done')
        } catch (error) {
            report(error)
        } finally {
            dispatch('loading/remove', 'fetch-listeners')
        }
    })

    on('add-listener', async function (submitted) {
        const companyId = state.company.id
        const teamId = state.team.id

        const leaderPhoneNumber = state.user.data.phoneNumber
        const phone = submitted.phoneNumber

        dispatch('loading/add', 'add-listener')

        try {
            const id = await addListener(companyId, teamId, submitted, leaderPhoneNumber)

            emit('fetch-listener', id)
            await once('fetch-listener/done')

            emit('add-listener/done')
        } catch (error) {
            if (error.message === 'Phone number already registered') {
                dispatch('notice/warning', phone + ' is already registered')
            } else if (error.message === 'Phone number is the same as yours') {
                dispatch(
                    'notice/warning',
                    phone + ' is already registered - this is your phone number'
                )
            } else if (error.message === 'Phone number exists in another team') {
                dispatch('notice/warning', phone + ' is already registered in another team')
            } else {
                report(error)
            }
        } finally {
            dispatch('loading/remove', 'add-listener')
        }
    })

    on('generate-import-report', async function (listeners) {
        const companyId = state.company.id
        const teamId = state.team.id

        dispatch('loading/add', {key: 'generate-import-report', blocking: true})

        try {
            const report = await batchSetListeners({
                companyId,
                teamId,
                listeners,
                generateReport: true
            })
            const {deleted: removed, ...rest} = report.data

            const result = {removed, ...rest}

            emit('generate-import-report-success', result)
        } catch (error) {
            report(error)
        } finally {
            dispatch('loading/remove', 'generate-import-report')
        }
    })

    on('import-listeners', async function (listeners) {
        const companyId = state.company.id
        const teamId = state.team.id

        dispatch('loading/add', {key: 'import-listeners', blocking: true})

        try {
            const result = await batchSetListeners({companyId, teamId, listeners})

            if (result.data.success === true) {
                emit('fetch-listeners')
                await once('fetch-listeners/done')

                dispatch('loading/remove', 'import-listeners')
                router.replace({name: 'listeners'})
            } else {
                throw new Error(result.data.message)
            }
        } catch (error) {
            dispatch('loading/remove', 'import-listeners')
            report(error)
        }
    })

    on('save-listener', async function ({id, ...rest}) {
        const companyId = state.company.id
        const teamId = state.team.id
        const leaderPhoneNumber = state.user.data.phoneNumber

        const phoneNumber = rest.phoneNumber

        dispatch('loading/add', {key: 'save-listener', blocking: true})

        try {
            await updateListener(companyId, teamId, id, rest, leaderPhoneNumber)

            emit('fetch-listener', id)
            await once('fetch-listener/done')

            dispatch('loading/remove', 'save-listener')
            dispatch('notice/success', 'Saved')
        } catch (error) {
            dispatch('loading/remove', 'save-listener')

            if (error.message === 'Phone number already registered') {
                dispatch('notice/warning', phoneNumber + ' is already registered')
            } else if (error.message === 'Phone number is the same as yours') {
                dispatch(
                    'notice/warning',
                    phoneNumber + ' is already registered - this is your phone number'
                )
            } else if (error.message === 'Phone number exists in another team') {
                dispatch(
                    'notice/warning',
                    phoneNumber + ' is already registered in another team'
                )
            } else {
                report(error)
            }
        }
    })

    on('delete-listener', async function (id) {
        const companyId = state.company.id
        const teamId = state.team.id

        dispatch('loading/add', {key: 'delete-listener', blocking: true})

        try {
            await deleteListener(companyId, teamId, id)

            dispatch('loading/remove', 'delete-listener')
            dispatch('listeners/remove', id)

            emit('delete-listener/done')

            router.replace({name: 'listeners'})
        } catch (error) {
            dispatch('loading/remove', 'delete-listener')
            report(error)
        }
    })
}
