import Vue from 'vue'

export default {
    namespaced: true,

    state: {},

    getters: {
        has(state) {
            return function (key) {
                return state.hasOwnProperty(key)
            }
        },

        get(state) {
            return function (key) {
                return state[key]
            }
        }
    },

    mutations: {
        SET(state, {key, ...object}) {
            Vue.set(state, key, object)
        },

        REMOVE(state, key) {
            Vue.delete(state, key)
        }
    },

    actions: {
        set({commit}, {key, entries}) {
            const entry = {
                key,
                entries
            }

            commit('SET', entry)
        },

        remove({commit}, key) {
            commit('REMOVE', key)
        }
    }
}
