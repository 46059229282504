<template>
    <layout-main background>
        <atom-button
            slot="header-left"
            grey
            icon="left-arrow"
            route="upload"
            aria-label="Back"
        />

        <atom-button
            slot="header-right"
            type="submit"
            form="form"
            :disabled="loading"
            text="Publish"
        />

        <atom-title slot="top" tag="h2" text="New track" />

        <atom-form id="form" @submit="submit" :disabled="loading">
            <atom-label text="Title" small>
                <molecule-text-input
                    name="title"
                    v-model="title"
                    :disabled="isPublishing"
                    required
                />
            </atom-label>

            <atom-label tag="span" text="Team" small />

            <molecule-list tag="fieldset" aria-label="Select the receiving team">
                <molecule-list-item
                    v-for="{id, name} in teams"
                    :key="id"
                    tag="label"
                    :text="name"
                >
                    <input
                        slot="right"
                        :disabled="loading"
                        required
                        name="team"
                        v-model="teamId"
                        @change="getTimeslots"
                        type="radio"
                        :value="id"
                    />
                </molecule-list-item>
            </molecule-list>

            <template v-if="computedTimeslots.length">
                <atom-label tag="span" text="Scheduling" small />

                <molecule-list tag="fieldset" appendable aria-label="Choose time slot">
                    <molecule-list-item
                        v-for="{
                            value,
                            formatedDateTime,
                            formatedRelativeTime
                        } in computedTimeslots"
                        :key="value"
                        tag="label"
                        :text="formatedDateTime"
                        :label="formatedRelativeTime"
                    >
                        <input
                            slot="right"
                            v-model="timeslot"
                            :disabled="loading"
                            required
                            name="timeslot"
                            type="radio"
                            :value="value"
                        />
                    </molecule-list-item>
                </molecule-list>

                <molecule-list-item
                    tag="button"
                    type="button"
                    last
                    text="Choose another"
                    @click="getMoreTimeslots"
                />
            </template>
        </atom-form>
    </layout-main>
</template>

<script>
    import {getDateTimeFormat, getRelativeTimeFormat} from '../utilities/date.js'

    export default {
        inject: ['emit', 'on'],

        data() {
            return {
                now: new Date(),
                timeslots: [],
                timeslotCount: undefined,

                title: undefined,
                teamId: undefined,
                timeslot: undefined
            }
        },

        watch: {
            now() {
                if (this.timeslot) {
                    if (this.now > new Date(this.timeslot)) {
                        this.timeslot = undefined
                    }
                }
            },

            teamId() {
                this.timeslot = undefined
                this.timeslots = []
            }
        },

        computed: {
            loading() {
                return (
                    this.$store.state.loading.hasOwnProperty('get-timeslots') ||
                    this.$store.state.loading.hasOwnProperty('create-track')
                )
            },

            isPublishing() {
                return this.$store.state.loading.hasOwnProperty('create-track')
            },

            teams() {
                return this.$store.getters['teams/asArray']
            },

            computedTimeslots() {
                const now = this.now

                return this.timeslots.reduce(function (accumulator, timeslot) {
                    const date = new Date(timeslot)

                    if (date > now) {
                        accumulator.push({
                            formatedDateTime: getDateTimeFormat(date),
                            formatedRelativeTime: getRelativeTimeFormat(now, date),
                            value: timeslot
                        })
                    }

                    return accumulator
                }, [])
            }
        },

        methods: {
            submit() {
                const submitted = {
                    title: this.title,
                    teamId: this.teamId,
                    timeslot: this.timeslot
                }

                this.emit('create-track', submitted)
            },

            getTimeslots() {
                this.emit('get-timeslots', this.teamId)
            },

            getMoreTimeslots() {
                const timeslots = this.timeslots
                const timeslotCount = this.timeslotCount

                // not sure what this is...
                // it is based on Chris's old code:
                // const days = state.company.data.schedule.days || 0
                // const existingDates = state.availableTimeslots.data.slice(-days.length)
                const currentTimeslots = timeslots.slice(-timeslotCount)

                this.emit('get-more-timeslots', {
                    teamId: this.teamId,
                    currentTimeslots
                })
            }
        },

        beforeRouteEnter(to, from, next) {
            const direct = from.name === null

            if (direct) {
                next({name: 'upload', replace: true})
            } else {
                next()
            }
        },

        created() {
            const minute = 60 * 1000

            const secondsToBlankMinute = 60 - this.now.getSeconds()

            const timeout = setTimeout(() => {
                const interval = setInterval(() => {
                    this.now = new Date()
                }, minute)

                this.now = new Date()

                this.$once('hook:beforeDestroy', function () {
                    clearInterval(interval)
                })
            }, secondsToBlankMinute * 1000)

            const offGetTimeslots = this.on('get-timeslots/done', (timeslots) => {
                this.timeslots = timeslots
                this.timeslotCount = timeslots.length
            })

            const offGetMoreTimeslots = this.on('get-more-timeslots/done', (timeslots) => {
                this.timeslots.push(...timeslots)
            })

            this.$once('hook:beforeDestroy', function () {
                clearTimeout(timeout)
                offGetTimeslots()
                offGetMoreTimeslots()
            })

            this.teamId = this.teams[0].id
            this.getTimeslots()
        }
    }
</script>
