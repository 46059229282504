<template>
    <layout-main width="small">
        <atom-button slot="header-right" grey icon="cross" route="sign-in" aria-label="Back" />

        <atom-title :text="'Enter the code sent to you at ' + phone" />

        <atom-form @submit="submit" :disabled="loading">
            <!--
                 https://technology.blog.gov.uk/ \
                 2020/02/24/why-the-gov-uk-design-system-team-changed-the-input-type-for-numbers
            -->

            <molecule-text-input
                name="code"
                v-model.trim="code"
                :disabled="loading"
                required
                inputmode="numeric"
                pattern="[0-9]*"
                grey
                placeholder="Verification code"
            />

            <atom-text tag="small" small light>
                I haven’t
                <atom-link to="sign-in">received a code, or it was expired</atom-link>.
            </atom-text>

            <atom-button
                id="sign-in-button"
                type="submit"
                :disabled="loading"
                full-width
                text="Sign in"
            />
        </atom-form>
    </layout-main>
</template>

<script>
    export default {
        inject: ['emit'],

        data() {
            return {
                code: undefined
            }
        },

        computed: {
            loading() {
                return this.$store.state.loading.hasOwnProperty('verify-auth-code')
            },

            phone() {
                return this.$store.state.login.phone.replace(/\s/g, '')
            }
        },

        methods: {
            submit() {
                const {code} = this

                this.emit('verify-auth-code', {code})
            }
        }
    }
</script>
