<template>
    <layout-main background v-if="isRootRoute">
        <atom-button
            slot="header-left"
            grey
            icon="left-arrow"
            route="settings"
            aria-label="Back"
        />

        <atom-title slot="top" :text="team.name" />

        <template v-if="loading === false">
            <atom-label light small text="Team" />

            <molecule-list
                :items="[
                    {route: 'team-name', text: 'Name', append: team.name},
                    {route: 'listeners', text: 'Listeners', append: team.listenersCount},
                    {route: 'schedule', text: 'Scheduling'}
                ]"
            />

            <molecule-list-item tag="button" type="button" @click="remove" text="Delete team">
                <atom-icon slot="left" icon="trash-can" color="grey" />
                <atom-icon slot="append" icon="small-right-arrow" color="grey" />
            </molecule-list-item>
        </template>
    </layout-main>

    <router-view v-else-if="loading === false" />
</template>

<script>
    export default {
        inject: ['emit'],

        computed: {
            isRootRoute() {
                return this.$route.name === 'team'
            },

            loading() {
                return (
                    this.$store.state.loading.hasOwnProperty('fetch-team-' + this.id) &&
                    this.team.$isPartiallyLoaded === true
                )
            },

            id() {
                return this.$store.state.team.id
            },

            team() {
                return this.$store.state.teams[this.id]
            }
        },

        methods: {
            remove() {
                // TODO: rewrite to use the dialig/modal system
                if (window.confirm('Are you sure you want to delete this team?')) {
                    this.emit('delete-team', this.id)
                }
            }
        }
    }
</script>
