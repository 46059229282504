<template>
    <layout-main background>
        <atom-button
            slot="header-left"
            grey
            icon="left-arrow"
            route="settings"
            aria-label="Back"
        />
        <atom-button
            slot="header-right"
            type="submit"
            form="form"
            :disabled="loading"
            text="Save"
        />

        <atom-title slot="top" text="Profile" />

        <atom-form id="form" @submit="submit" :disabled="loading">
            <atom-label light small text="Company name" />

            <molecule-text-input
                name="company-name"
                v-model="companyName"
                :disabled="loading"
                required
            />

            <atom-label light small text="Logo" />

            <atom-card v-if="logo">
                <atom-flex
                    rules="auto, auto"
                    space-between
                    align="bottom"
                    style="margin-bottom: 16px"
                >
                    <atom-company-logo large :url="logo" />
                    <atom-button icon="trash-can" grey text="Remove" @click="remove" />
                </atom-flex>
            </atom-card>

            <template v-else>
                <molecule-file-input
                    name="company-logo"
                    :disabled="loading"
                    ref="file-input"
                    accept="image/png, image/jpeg"
                    label="Import photo"
                    @select="verifyLogo"
                />

                <atom-text small light>
                    Your company logo must be square and .png or jpg.
                    <br />
                    The file size must not exceed 128 KB.
                </atom-text>
            </template>
        </atom-form>
    </layout-main>
</template>

<script>
    function createDataURL(file) {
        const reader = new FileReader()

        return new Promise(function (resolve, reject) {
            reader.addEventListener('load', function (event) {
                resolve(event.target.result)
            })

            reader.addEventListener('error', function () {
                reject(new Error('Error when reading file'))
            })

            reader.readAsDataURL(file)
        })
    }

    function createImagePreview(dataURL) {
        let image = new Image()

        return new Promise(function (resolve, reject) {
            image.addEventListener('load', function (event) {
                resolve(image)
            })

            image.addEventListener('error', function () {
                reject(new Error('Error when creating preview of image'))
            })

            image.src = dataURL
        })
    }

    export default {
        inject: ['emit'],

        data() {
            return {
                companyName: undefined,
                logo: undefined
            }
        },

        computed: {
            loading() {
                return this.$store.state.loading.hasOwnProperty('save-profile')
            }
        },

        methods: {
            async verifyLogo() {
                const input = this.$refs['file-input']
                const file = input.selected

                const kilobyte = file.size / 1000

                const isBig = kilobyte > 128

                if (isBig) {
                    this.$store.dispatch('notice/info', 'The logo was over 128kB.')
                    input.reset()
                } else {
                    try {
                        const dataURL = await createDataURL(file)
                        const image = await createImagePreview(dataURL)

                        const {height, width} = image

                        const ratio = height / width

                        if (ratio !== 1) {
                            this.$store.dispatch('notice/info', 'The logo was not square.')
                            input.reset()
                        }
                    } catch (error) {
                        if (error.message === 'Error when reading file') {
                            this.$store.dispatch(
                                'notice/warning',
                                'Cannot read the file. Perhaps it is corrupt?'
                            )
                        } else if (error.message === 'Error when creating preview of image') {
                            this.$store.dispatch(
                                'notice/warning',
                                'Cannot create a preview of the image. Perhaps it is corrupt?'
                            )
                        } else {
                            this.$store.dispatch('notice/warning', error)
                        }
                    }
                }
            },

            remove() {
                this.logo = undefined
            },

            submit() {
                const fileInput = this.$refs['file-input']
                const companyData = this.$store.state.company.data

                const hasLogo = companyData.hasOwnProperty('logo')
                const selectedLogo = fileInput && fileInput.selected

                const newLogo = selectedLogo || false
                const removeLogo = hasLogo && this.logo === undefined

                const submitted = {
                    companyName: this.companyName,
                    logo: newLogo ? newLogo : removeLogo ? false : undefined
                }

                this.emit('save-profile', submitted)
            }
        },

        created() {
            const companyData = this.$store.state.company.data
            const billing = this.$store.state.company.billing

            this.companyName = billing.companyName
            this.logo = companyData.logo ? companyData.logo.downloadUrl : undefined
        }
    }
</script>
