<template>
    <layout-main background>
        <atom-button
            slot="header-left"
            grey
            icon="left-arrow"
            route="team"
            aria-label="Back"
        />

        <atom-button
            slot="header-right"
            type="submit"
            form="form"
            :disabled="loading"
            text="Save"
        />

        <atom-title slot="top" text="Scheduling" />

        <atom-form id="form" @submit="submit" :disabled="loading">
            <atom-label light small text="Settings" />

            <molecule-time-input
                name="time-slot"
                v-model="timeSlot"
                :disabled="loading"
                required
                label="Timeslot"
            />

            <!-- https://en.wikipedia.org/wiki/ISO_week_date -->
            <!-- weekday number, a digit from 1 through 7, beginning with Monday and ending with Sunday -->
            <molecule-list tag="fieldset" aria-label="Choose days">
                <molecule-list-item tag="label" text="Monday">
                    <input
                        slot="right"
                        name="day"
                        type="checkbox"
                        v-model="days"
                        :disabled="loading"
                        :required="days.length === 0"
                        :value="1"
                    />
                </molecule-list-item>

                <molecule-list-item tag="label" text="Tuesday">
                    <input
                        slot="right"
                        name="day"
                        type="checkbox"
                        v-model="days"
                        :disabled="loading"
                        :required="days.length === 0"
                        :value="2"
                    />
                </molecule-list-item>

                <molecule-list-item tag="label" text="Wednesday">
                    <input
                        slot="right"
                        name="day"
                        type="checkbox"
                        v-model="days"
                        :disabled="loading"
                        :required="days.length === 0"
                        :value="3"
                    />
                </molecule-list-item>

                <molecule-list-item tag="label" text="Thursday">
                    <input
                        slot="right"
                        name="day"
                        type="checkbox"
                        v-model="days"
                        :disabled="loading"
                        :required="days.length === 0"
                        :value="4"
                    />
                </molecule-list-item>

                <molecule-list-item tag="label" text="Friday">
                    <input
                        slot="right"
                        name="day"
                        type="checkbox"
                        v-model="days"
                        :disabled="loading"
                        :required="days.length === 0"
                        :value="5"
                    />
                </molecule-list-item>
            </molecule-list>
        </atom-form>
    </layout-main>
</template>

<script>
    export default {
        inject: ['emit'],

        data() {
            return {
                timeSlot: '08:00',
                days: []
            }
        },

        computed: {
            id() {
                return this.$store.state.team.id
            },

            loading() {
                return this.$store.state.loading.hasOwnProperty('save-schedule')
            }
        },

        methods: {
            submit() {
                const submitted = {
                    timeSlot: this.timeSlot,
                    days: this.days
                }

                this.emit('save-schedule', submitted)
            }
        },

        created() {
            const schedule = this.$store.state.teams[this.id].schedule

            if (schedule && schedule.timeSlot) {
                this.timeSlot = schedule.timeSlot
            }

            if (schedule && schedule.days) {
                this.days = schedule.days
            }
        }
    }
</script>
