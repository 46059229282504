// @format
export const setCompany = async (companyId, companyDetails) => {
    const companyRef = firebase.firestore().collection('companies').doc(companyId)
    return await companyRef.update({
        ...companyDetails
    })
}

export const fetchCompany = async (companyId) => {
    const companyRef = firebase.firestore().collection('companies').doc(companyId)

    const companyDoc = await companyRef.get()

    return companyDoc.data()
}

export const fetchAllCompanies = async () => {
    const companiesRef = firebase.firestore().collection('companies')

    const companySnapshot = await companiesRef.get()
    let companies = []

    companySnapshot.forEach((doc) => companies.push({id: doc.id, ...doc.data()}))

    return companies
}

export const subscribeToCompany = (companyId, onSnapshot) => {
    const companyRef = firebase.firestore().collection('companies').doc(companyId)

    companyRef.onSnapshot((doc) => {
        onSnapshot(doc.data())
    })
}

export async function fetchBillingHistory(companyId) {
    const query = firebase
        .firestore()
        .collection('companies')
        .doc(companyId)
        .collection('billingHistory')

    const querySnapshot = await query.get()

    return querySnapshot.docs.map(function (document) {
        const data = document.data()

        return data
    })
}
