export default function connect({globals: {store, addEventListener, router}}) {
    const {getters, dispatch} = store

    addEventListener('beforeunload', function (event) {
        if (process.env.NODE_ENV !== 'production') {
            return
        }

        if (getters['loading/isLoading']) {
            event.returnValue = '' // chrome requirement
            event.preventDefault()
        }
    })

    router.beforeEach(function (to, from, next) {
        const isInitial = from.name === null

        if (isInitial) {
            next()
        } else if (getters['loading/isBlocked']) {
            dispatch('notice/info', 'Navigation blocked while loading')
            next(false)
        } else {
            next()
        }
    })
}
