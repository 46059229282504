<template>
    <layout-main
        width="large"
        background
        :header-title="team.name"
        :header-label="listeners.length + ' listeners'"
    >
        <atom-button
            slot="header-left"
            grey
            icon="left-arrow"
            route="team"
            aria-label="Back"
        />

        <atom-button
            slot="header-right"
            grey
            route="listener-import"
            icon="import"
            aria-label="Last opp CSV-fil"
        />

        <template slot="top">
            <atom-title size="medium" text="Add new" />

            <atom-form id="form" @submit="submit" :disabled="loading">
                <atom-flex>
                    <molecule-text-input
                        name="firstname"
                        v-model="firstname"
                        :disabled="loading"
                        required
                        grey
                        placeholder="First name"
                    />

                    <molecule-text-input
                        name="lastname"
                        v-model="lastname"
                        :disabled="loading"
                        required
                        grey
                        placeholder="Last name"
                    />
                </atom-flex>

                <atom-flex rules="100%, auto">
                    <molecule-phone-input
                        name="phone"
                        :value="{countryCode, phone}"
                        @change="updatePhone"
                        :disabled="loading"
                        required
                        grey
                        placeholder="Phone number"
                    />

                    <atom-button type="submit" :disabled="loading" large icon="plus" />
                </atom-flex>

                <atom-text
                    small
                    light
                    text="New listeners will be added to your monthly subscription."
                />
            </atom-form>
        </template>

        <template v-if="listeners.length">
            <atom-label light small text="All" />

            <molecule-list :items="listeners" />
        </template>
    </layout-main>
</template>

<script>
    function formatPhoneNumber({countryCode, phoneNumber}) {
        return countryCode + ' ' + phoneNumber.replace(countryCode, '')
    }

    export default {
        inject: ['emit', 'on'],

        data() {
            return {
                firstname: undefined,
                lastname: undefined,
                countryCode: undefined,
                phone: undefined
            }
        },

        computed: {
            id() {
                return this.$store.state.team.id
            },

            team() {
                return this.$store.state.teams[this.id]
            },

            loading() {
                return this.$store.state.loading.hasOwnProperty('add-listener')
            },

            listeners() {
                const listeners = this.$store.getters['listeners/asArray']

                return Array.from(listeners) // because the sort mutates
                    .sort(function (a, b) {
                        return a.firstName.localeCompare(b.firstName)
                    })
                    .map(function (listener) {
                        const {countryCode, phoneNumber} = listener

                        return {
                            text: listener.firstName,
                            append: formatPhoneNumber({countryCode, phoneNumber}),
                            route: {name: 'listener', params: {listenerId: listener.id}}
                        }
                    })
            }
        },

        methods: {
            updatePhone({countryCode, phone}) {
                this.countryCode = countryCode
                this.phone = phone
            },

            reset() {
                this.firstname = undefined
                this.lastname = undefined
                this.phone = undefined
            },

            submit() {
                const submitted = {
                    firstName: this.firstname,
                    lastName: this.lastname,
                    countryCode: this.countryCode,
                    phoneNumber: this.phone.replace(/\s/g, '')
                }

                this.emit('add-listener', submitted)
            }
        },

        created() {
            const off = this.on('add-listener/done', this.reset)

            this.$once('hook:beforeDestroy', off)
        }
    }
</script>
